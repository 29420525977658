import React, { useState } from 'react'
import { Box, Button, Container, FormControl, FormControlLabel, Switch, TextField } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { AddLeaveType } from '../../component/service/apis/leave/Leave.api';

const AddLeavesType = (props) => {
    const [leave, setLeave] = useState('');
    const [leaveBalance, setLeaveBalance] = useState('');
    const [carryForward, setCarryForward] = useState(false);
    const [paidLeave, setPaidLeave] = useState(false);

    const handleAdd = () => {
        const data = {
            name: leave,
            balance: leaveBalance,
            carry_forward: carryForward,
            paid_leave: paidLeave
        }
        AddLeaveType(data).then((res) => {
            res === true && props.handleLeaveTypeList()
        })
        setLeave('');
        props.setOpen(false)
    }

    const Body = <React.Fragment>
        <Container className='popup' sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
            }}>
                <p>
                    Add Leave
                </p>
            </Box>
            <Box sx={{ width: '100%' }}>
                <FormControl sx={{ m: 1, display: 'flex', gap: '.5em' }}>
                    <TextField id="role" label="Leave Name" variant="outlined" onChange={(e) => setLeave(e.target.value)} />
                    <TextField type='number' id="role" label="Max Leave Per Month" variant="outlined" onChange={(e) => setLeaveBalance(e.target.value)} />
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <FormControlLabel
                            control={<Switch id="carryForward" checked={carryForward} onChange={(e) => setCarryForward(e.target.checked)} />}
                            label="Carry Forward"
                        />
                        <FormControlLabel
                            control={<Switch id="paidLeave" checked={paidLeave} onChange={(e) => setPaidLeave(e.target.checked)} />}
                            label="Paid Leave"
                        />
                    </div>

                    <Button style={{ backgroundColor: 'rgba(21, 64, 138,.8)' }} size='large' onClick={() => handleAdd()} variant="contained">Add Leave</Button>
                    <Button style={{ opacity: '.8' }} color='error' size='large' variant="outlined" onClick={props.onHide}>Cancel</Button>
                </FormControl>
            </Box>
        </Container>
    </React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default AddLeavesType