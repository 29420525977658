import React, { useEffect, useState } from 'react'
import { Box, Button, Container, IconButton, TextField, Tooltip } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import './user.css'
import { ButtonGroup } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import SelectDocuments from '../documents/SelectDocuments';
import { getDepartment } from '../../component/service/apis/department/department.api';
import { RegisterUser } from '../../component/service/apis/user/User.api';
import { getRole } from '../../component/service/apis/role/Role.api';
import { getPosition } from '../../component/service/apis/position/Position.api';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { validatePassword } from '../../component/service/CommonFunctions';

const RegisterNewUser = (props) => {
    const [role, setRole] = useState([]);
    const [position, setPosition] = useState([]);
    const [department, setDepartment] = useState([]);
    const [docsName, setDocsName] = React.useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        employeeID: '',
        role: '',
        position: '',
        department: '',
        age: '',
        blood_group: '',
        contact: '',
        gender: '',
        street_address: '',
        city: '',
        state: '',
        zip_code: '',
        country: '',
        // baseSalary: '',
        alt_contact: '',
        relation: '',
        relation_name: '',
        joiningDate: new Date().toISOString().split('T')[0],
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    let passwordValidation;
    if (newUser.password) {
        passwordValidation = validatePassword(newUser.password);
    }

    useEffect(() => {
        const fetchRolePosition = async () => {
            const roledata = await getRole();
            setRole(roledata);
            const posdata = await getPosition();
            setPosition(posdata);
            const deptdata = await getDepartment();
            setDepartment(deptdata);
        }
        fetchRolePosition();
    }, [])

    const handleRegisterNewUser = async () => {
        RegisterUser(newUser, docsName).then((res) => {
            res && props.handleUserList()
        })
        props.setOpen(false);
    }

    const Body = <React.Fragment>
        <Container className='popup' sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
            }}>
                <p>
                    Register User
                </p>
            </Box>
            <Box sx={{ width: '100%' }}>
                <div className='userForm'>
                    <TextField size='small' className='TextField' value={newUser.name || ''} onChange={handleChange} type='text' name='name' id="name" label="Name" variant="outlined" />
                    <TextField size='small' className='TextField' value={newUser.email || ''} onChange={handleChange} type='email' name='email' id="email" label="Email" variant="outlined" />
                    <TextField size='small' className='TextField' value={newUser.employeeID || ''} onChange={handleChange} type='text' name='employeeID' id="employeeID" label="Employee ID" variant="outlined" />

                    <FormControl size='small' className='TextField'>
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                            size='small'
                            labelId="role-select-label"
                            id="role"
                            name="role"
                            value={newUser.role}
                            label="Role"
                            onChange={handleChange}
                        >
                            {role && role.length > 0 && role.map((role) => (
                                <MenuItem key={role.id} value={role.id}>{role.role_name}</MenuItem> // Adjust based on your data structure
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl size='small' className='TextField'>
                        <InputLabel id="position-select-label">Position</InputLabel>
                        <Select
                            size='small'
                            labelId="position-select-label"
                            id="position"
                            name="position"
                            value={newUser.position}
                            label="Position"
                            onChange={handleChange}
                        >
                            {position && position.length > 0 && position.map((position) => (
                                <MenuItem key={position.id} value={position.id}>{position.position_name}</MenuItem> // Adjust based on your data structure
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl size='small' className='TextField'>
                        <InputLabel id="position-select-label">Department</InputLabel>
                        <Select
                            size='small'
                            labelId="department-select-label"
                            id="department"
                            name="department"
                            value={newUser.department}
                            label="Department"
                            onChange={handleChange}
                        >
                            {department && department.length > 0 && department.map((department) => (
                                <MenuItem key={department.id} value={department.id}>{department.department_name}</MenuItem> // Adjust based on your data structure
                            ))}
                        </Select>
                    </FormControl>

                    <TextField size='small' className='TextField' value={newUser.age || ''} onChange={handleChange} type='number' name='age' id="age" label="Age" variant="outlined" />
                    <TextField size='small' className='TextField' value={newUser.blood_group || ''} onChange={handleChange} type='text' name='blood_group' id="blood_group" label="Blood Group" variant="outlined" />
                    <TextField size='small' className='TextField' value={newUser.contact || ''} onChange={handleChange} type='number' name='contact' id="contact" label="Contact" variant="outlined" />

                    <FormControl size='small' className='TextField'>
                        <InputLabel id="position-select-label">Gender</InputLabel>
                        <Select
                            size='small'
                            labelId="department-select-label"
                            id="gender"
                            name="gender"
                            value={newUser.gender}
                            label="Gender"
                            onChange={handleChange}
                        >
                            <MenuItem value='male'>Male</MenuItem>
                            <MenuItem value='female'>Female</MenuItem>
                            <MenuItem value='others'>others</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField size='small' className='TextField' value={newUser.alt_contact || ''} onChange={handleChange} type='number' name='alt_contact' id="alt_contact" label="Alt Contact" variant="outlined" />
                    <TextField size='small' className='TextField' value={newUser.relation || ''} onChange={handleChange} type='text' name='relation' id="relation" label="Relation" variant="outlined" />

                    <TextField size='small' className='TextField' value={newUser.relation_name || ''} onChange={handleChange} type='text' name='relation_name' id="relation_name" label="Relation Name" variant="outlined" />
                    <TextField size='small' className='TextField' value={newUser.joiningDate || ''} onChange={handleChange} type='date' name='joiningDate' id="joiningDate" label="Joining Date" variant="outlined" />

                    <SelectDocuments setDocsName={setDocsName} docsName={docsName} />



                    <Tooltip className='TextField' title={passwordValidation?.message || ''} open={!!(newUser.password)} arrow>
                        <TextField size='small' id="outlined-basic" name='password' label="Enter Password" type={showPassword ? 'text' : 'password'} variant="outlined" value={newUser.password} onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => setShowPassword(showPassword === true ? false : true)} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                )
                            }}
                            required />
                    </Tooltip >
                    <TextField size='small' className='TextField' value={newUser.street_address || ''} onChange={handleChange} type='text' name='street_address' id="Street Address" label="street_address" variant="outlined" />

                    <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                        <Button sx={{ width: '100%', opacity: '.8', backgroundColor: 'rgba(21, 64, 138,.8)' }} onClick={() => handleRegisterNewUser()} variant='contained'>submit</Button>
                        <Button sx={{ width: '100%', opacity: '.8' }} onClick={props.onHide} color='error'>Cancel</Button>
                    </ButtonGroup>
                </div>
            </Box>
        </Container>
    </React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default RegisterNewUser



// <TextField size='small' className='TextField' value={newUser.baseSalary || ''} onChange={handleChange} type='number' name='baseSalary' id="baseSalary" label="Base Salary" variant="outlined" />