import { notifyError, notifySuccess } from "../../../notify/Notify";
import { deleteRequestWithFetch, getRequestWithFetch, patchRequestWithFetch, postRequestWithFetch, postRequestWithFetchForFile } from "../../FetchingMethod";

const AddDocuments = async (body) => {
    const res = await postRequestWithFetch(`documents/add`, body)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const updateDocuments = async ({ docTypeId, docTypeName }) => {
    const res = await patchRequestWithFetch(`documents/update/${docTypeId}`, { docTypeName })
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}
    
const deleteDocumentsType = async ({ docTypeId }) => {
    const res = await deleteRequestWithFetch(`documents/delete/${docTypeId}`)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const getDocumentsList = async () => {
    const res = await getRequestWithFetch(`documents/list`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

const getUserDocumentsList = async (userId, status) => {
    let userID = userId === '' ? '' : `/${userId}`;
    const res = await getRequestWithFetch(`documents/user/${status}${userID}`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

const handleUploadAskedFile = async (docId, body) => {
    const formData = new FormData();
    formData.append('file', body);
    const res = await postRequestWithFetchForFile(`documents/upload/${docId}`, formData)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message });
        return res.status
    } else {
        notifyError({ Message: res.error });
    }
}

export {
    AddDocuments,
    updateDocuments,
    deleteDocumentsType,
    getDocumentsList,
    getUserDocumentsList,
    handleUploadAskedFile
}