import { notifyError, notifySuccess } from "../../../notify/Notify";
import { getRequestWithFetch, patchRequestWithFetch, postRequestWithFetch } from "../../FetchingMethod";

const getShift = async () => {
    const res = await getRequestWithFetch(`shift/list`)
    if (res?.status === true) {
        return await res?.data;
    } else {
        notifyError({ Message: res?.error || "Server Error" });
    }
}


const addShift = async (body) => {
    const res = await postRequestWithFetch(`shift/add`, body)
    if (res?.status === true) {
        notifySuccess({ Message: res?.message })
        return await res?.status;
    } else {
        notifyError({ Message: res?.error || "Server Error" });
    }
}

const updateShift = async (shiftId, body) => {
    const res = await patchRequestWithFetch(`shift/update/${shiftId}`, body)
    if (res?.status === true) {
        notifySuccess({ Message: res?.message })
        return await res?.status;
    } else {
        notifyError({ Message: res?.error });
    }
}

const getAssignedUserList = async (userId) => {
    const res = await getRequestWithFetch(`shift/assigned/users/list/${userId || ''}`)
    if (res?.status === true) {
        return await res?.data;
    } else {
        notifyError({ Message: res?.error || "Server Error" });
    }
}
const assignShiftToUsers = async (data) => {
    const res = await postRequestWithFetch(`shift/assign/users`, data)
    if (res?.status === true) {
        notifySuccess({ Message: res?.message });
        return res?.status;
    } else {
        notifyError({ Message: res?.error || "Server Error" });
    }
}

const assignShiftToUser = async (data) => {
    const res = await postRequestWithFetch(`shift/assign/user`, data)
    if (res?.status === true) {
        notifySuccess({ Message: res?.message });
        return res?.status;
    } else {
        notifyError({ Message: res?.error || "Server Error" });
    }
}

export {
    getShift,
    addShift,
    updateShift,
    getAssignedUserList,
    assignShiftToUser,
    assignShiftToUsers,
}