import React from 'react';
import { Button, FormControl, Input } from '@mui/material';
import { Typography } from '@mui/material';
import { handleUploadAskedFile } from '../../component/service/apis/documents/documents.api';

const FileUploadComponent = ({ fileData }) => {

    const uploadFile = async (docId, file) => {
        await handleUploadAskedFile(docId, file);
    };

    return (
        <div>
            {fileData.map((item, index) => (
                <React.Fragment>
                    <Typography variant="subtitle1" gutterBottom>
                        {item.docName}
                    </Typography>
                    <FormControl key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        <Input
                            id={`file-${item.id}`}
                            type="file"
                        />
                        <Button
                            variant="contained"
                            onClick={() => uploadFile(item.id, document.getElementById(`file-${item.id}`).files[0])}
                        >
                            Upload
                        </Button>
                    </FormControl>
                </React.Fragment>
            ))}
        </div>
    );
};

export default FileUploadComponent;
