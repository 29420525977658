// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    display: flex;
    align-items: center;
    background-color: rgb(255, 168, 87);
    border-radius: var(--g_radius);
    padding: 1.5em;
    width: 100%;
    box-shadow: var(--shadow);
}

.cardContent {
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
}

.Icondiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .7em;
    border-radius: 50px;
    background-color: rgb(255, 255, 255, .4);
    color: rgb(255, 255, 255);
}

.content {
    color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/component/card/ResponsiveCards.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mCAAmC;IACnC,8BAA8B;IAC9B,cAAc;IACd,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".card {\n    display: flex;\n    align-items: center;\n    background-color: rgb(255, 168, 87);\n    border-radius: var(--g_radius);\n    padding: 1.5em;\n    width: 100%;\n    box-shadow: var(--shadow);\n}\n\n.cardContent {\n    display: flex;\n    align-items: center;\n    gap: 1em;\n    width: 100%;\n}\n\n.Icondiv {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: .7em;\n    border-radius: 50px;\n    background-color: rgb(255, 255, 255, .4);\n    color: rgb(255, 255, 255);\n}\n\n.content {\n    color: rgb(255, 255, 255);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
