import React, { useState } from 'react';
import { Select, MenuItem, Chip, FormControl, Typography, Button, ButtonGroup } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import './user.css'
import { updateUserStatus } from '../../component/service/apis/user/User.api';

function StatusSelect(props) {
    const [status, setStatus] = useState(props.status);
    const [openPopup, setOpenPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(props.status);


    if (status === null) {
        return null;
    }

    const handleStatusChange = (event) => {
        setNewStatus(event.target.value);
        setOpenPopup(true);
    };

    const handleConfirmation = () => {
        const data = {
            userID: props.userID,
            status: newStatus
        }
        setStatus(newStatus);
        updateUserStatus(data).then((res) => {
            res && props.handleUserList();
        }) // Update the status on server, modify as needed
        setOpenPopup(false);
    };

    const getColor = (selected) => {
        let opacity = '0.5';
        return selected === 'active' ? 'rgba(76, 175, 80, ' + opacity + ')' : 'rgba(255, 193, 7, ' + opacity + ')';
    };

    const body = (
        <div className='popup'>
            <Typography variant="h5" gutterBottom>
                Are you sure to update Status?
            </Typography>
            <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                <Button sx={{ width: '100%', opacity: '.8' }} onClick={() => handleConfirmation()} color='success' variant='contained'>Yes</Button>
                <Button sx={{ width: '100%' }} onClick={() => setOpenPopup(false)} color='error'>Cancel</Button>
            </ButtonGroup>
        </div>
    );

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={status}
                    label="Status"
                    variant="standard"
                    style={{ width: 'fit-content' }}
                    onChange={handleStatusChange}
                    renderValue={(selected) => (
                        <Chip label={selected} sx={{ backgroundColor: getColor(selected) }} />
                    )}
                >
                    <MenuItem value='active'>
                        <Chip label="Active" sx={{ backgroundColor: 'rgba(76, 175, 80, .5)' }} />
                    </MenuItem>
                    <MenuItem value='inactive'>
                        <Chip label="InActive" sx={{ backgroundColor: 'rgba(255, 193, 7, .5)' }} />
                    </MenuItem>
                </Select>
            </FormControl>

            <ResponsiveDialog
                open={openPopup}
                onHide={() => setOpenPopup(false)}
                Content={body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    );
}

export default StatusSelect;
