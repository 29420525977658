import React, { useEffect } from 'react'
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog'
import { Container } from '@mui/material'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import BasicTimePicker from '../../component/BasicTimePicker/BasicTimePicker'
import { handleGetGracePeriod, handleGetHalfDay, handleGetTimeIn, handleGetTimeOut, handleGracePeriod, handleHalfDay, handleTimeIn, handleTimeOut } from '../../component/service/APIs'
import dayjs from 'dayjs';

const AttendanceConfig = (props) => {
    const [timeIn, setTimeIn] = React.useState(null);
    const [timeOut, setTimeOut] = React.useState(null);
    const [halfDay, setHalfDay] = React.useState(null);
    const [gracePeriod, setGracePeriod] = React.useState(null);

    const getCurrentDateTime = (newTime) => {
        if (newTime && dayjs(newTime).isValid()) {
            // Create a new dayjs object with today's date but time from the picker
            const combinedDateTime = dayjs().hour(dayjs(newTime).hour()).minute(dayjs(newTime).minute()).second(0).millisecond(0);
            return combinedDateTime;
        }
    }

    useEffect(() => {
        if (props.open) {
            handleGetTimeIn().then((data) => {
                setTimeIn(dayjs(data));
            });
            handleGetTimeOut().then((data) => {
                setTimeOut(dayjs(data));
            });
            handleGetHalfDay().then((data) => {
                setHalfDay(dayjs(data));
            });
            handleGetGracePeriod().then((data) => {
                setGracePeriod(dayjs(data));
            });
        }
    }, [props.open]);

    const Body = <React.Fragment>
        <Container className='popup' sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <Typography variant="h5" align="center">Attendance Configuration</Typography>
            <Box sx={{ width: '100%' }}>
                <div className='userForm'>
                    <BasicTimePicker
                        onTimeSelected={(newTime) => {
                            getCurrentDateTime(newTime);
                            handleTimeIn(timeIn); // Make API call here after confirmation
                        }}
                        label="Time In"
                        onChange={setTimeIn}
                        selectedTime={timeIn}
                    />
                    <BasicTimePicker
                        label="Time Out"
                        onChange={setTimeOut}
                        selectedTime={timeOut}
                        onTimeSelected={(newTime) => {
                            getCurrentDateTime(newTime);
                            handleTimeOut(timeOut); // Make API call here after confirmation
                        }}
                    />
                    <BasicTimePicker
                        label="Half Day"
                        onChange={setHalfDay}
                        selectedTime={halfDay}
                        onTimeSelected={(newTime) => {
                            getCurrentDateTime(newTime);
                            handleHalfDay(halfDay); // Make API call here after confirmation
                        }}
                    />
                    <BasicTimePicker
                        label="Grace Period"
                        onChange={setGracePeriod}
                        selectedTime={gracePeriod}
                        onTimeSelected={(newTime) => {
                            getCurrentDateTime(newTime);
                            handleGracePeriod(gracePeriod); // Make API call here after confirmation
                        }}
                    />
                </div>
            </Box>
        </Container>

    </React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default AttendanceConfig