import { notifyError, notifySuccess } from "../../../notify/Notify";
import { deleteRequestWithFetch, getRequestWithFetch, patchRequestWithFetch, postRequestWithFetch } from "../../FetchingMethod";

const getDepartment = async () => {
    const res = await getRequestWithFetch(`department/list`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

const addDepartment = async (body) => {
    const res = await postRequestWithFetch(`department/add`, body)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const updateDepartment = async ({ deptId, deptName }) => {
    const res = await patchRequestWithFetch(`department/update/${deptId}`, { deptName })
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const deleteDepartment = async ({ deptId }) => {
    const res = await deleteRequestWithFetch(`department/delete/${deptId}`)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

export {
    getDepartment,
    addDepartment,
    updateDepartment,
    deleteDepartment
}