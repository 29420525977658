import React, { useState } from 'react'
import { Box, Button, Container, FormControl, FormControlLabel, Switch, TextField } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { addShift } from '../../component/service/apis/shift/Shifts.api';
import dayjs from 'dayjs';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';

const AddShift = (props) => {

    const [shift, setShift] = useState({
        shiftName: '',
        timeIn: dayjs(),
        timeOut: dayjs(),
        halfDay: dayjs(),
        assignLateMark: false,
        approvalRequired: false,
        status: 'active',
        graceTime: ''
    });

    const handleShiftData = (e) => {
        const { name, value, type, checked } = e.target;

        setShift((prevShift) => ({
            ...prevShift,
            [name]: type === 'checkbox' ? checked : value
        }));
    }


    const handleAdd = () => {
        addShift(shift).then((res) => {
            res === true && props.handleShiftList()
            setShift({
                shiftName: '',
                timeIn: '',
                timeOut: '',
                halfDay: '',
                assignLateMark: false,
                approvalRequired: false,
                status: 'active',
                graceTime: ''
            });
        })
        props.setOpen(false)
    }

    const Body = <React.Fragment>
        <Container className='popup' sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
            }}>
                <p>
                    Add Shift
                </p>
            </Box>
            <Box sx={{ width: '100%' }}>
                <FormControl sx={{ m: 1, display: 'flex', gap: '.5em' }}>
                    <TextField type='text' value={shift.shiftName} id="shiftName" name="shiftName" label="Shift Name" variant="outlined" onChange={handleShiftData} />
                    <TextField type='time' value={shift.timeIn} id="timeIn" name="timeIn" label="Start Time" variant="outlined" onChange={handleShiftData} />
                    <TextField type='time' value={shift.timeOut} id="timeOut" name="timeOut" label="End Time" variant="outlined" onChange={handleShiftData} />
                    <TextField type='time' value={shift.halfDay} id="halfDay" name="halfDay" label="Half Day" variant="outlined" onChange={handleShiftData} />
                    {/*<TextField type='text' id="status" value={shift.status} name='status' label="Status" variant="outlined" onChange={handleShiftData} />*/}
                    <FormControl variant="outlined">
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            id="status"
                            value={shift.status}
                            name="status"
                            label="Status"
                            onChange={handleShiftData}
                        >
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField type='number' id="graceTime" value={shift.graceTime} name='graceTime' label="Grace Time (In Minutes)" variant="outlined" onChange={handleShiftData} />
                    <FormControlLabel
                        control={<Switch id="assignLateMark" value={shift.assignLateMark} name="assignLateMark" checked={shift.assignLateMark} onChange={handleShiftData} />}
                        label="Assign Late mark if punch in after grace time end"
                    />
                    <FormControlLabel
                        control={<Switch id="approvalRequired" value={shift.approvalRequired} name="approvalRequired" checked={shift.approvalRequired} onChange={handleShiftData} />}
                        label="Attendance Approval Required"
                    />
                    <Button style={{ backgroundColor: 'rgba(21, 64, 138,.8)' }} size='large' onClick={handleAdd} variant="contained">Add Shift</Button>
                    <Button style={{ opacity: '.8' }} color='error' size='large' variant="outlined" onClick={props.onHide}>Cancel</Button>
                </FormControl>
            </Box>
        </Container>
    </React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default AddShift