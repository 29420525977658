import React, { useState } from 'react'
import { Button, Chip, Divider, TextField, Tooltip, Typography, styled, IconButton } from '@mui/material';
import { validateEmail, validatePassword } from '../../component/service/CommonFunctions';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { notifyError } from '../../component/notify/Notify';
import { forgotPassword, login } from '../../component/service/apis/user/User.api';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const style = {
  width: "100%",
  opacity: ".7",
};

const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('')
  const [Password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const emailValidation = validateEmail(email);
  const passwordValidation = validatePassword(Password);

  // Login data
  const handleLogin = () => {
    if (email && Password) {
      const data = {
        email: email,
        password: Password,
      };
      login(data, navigate);
    } else {
      notifyError({ Message: "Please fill all Fields" });
    }
  };

  const handleForgetPassword = () => {
    if (email) {
      forgotPassword({ email: email }, navigate);
    } else {
      notifyError({ Message: "Please Provide email" });
    }
  };

  return (
    <div className="login">
      <div className="loginContainer">
        <Typography
          style={{ margin: "1em 1em 2em 1em" }}
          variant="h5"
          gutterBottom
        >
          Login
        </Typography>

        <Tooltip title={emailValidation?.message || ""} open={!!email} arrow>
          <Input
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@gmail.com"
            required
          />
        </Tooltip>

        <Tooltip title={passwordValidation?.message || ''} open={!!(Password)} arrow>
          <Input id="outlined-basic" name='password' label="Password" type={showPassword ? 'text' : 'password'} variant="outlined" value={Password} onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(showPassword === true ? false : true)} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )
            }}
            required />
        </Tooltip >

        <Button
          style={style}
          color="primary"
          onClick={() => handleLogin()}
          variant="contained"
          size="large"
        >
          Login
        </Button>

        <Divider style={{ marginTop: "1em", width: "100%" }}>
          <Chip label="OR" />
        </Divider>

        <Button
          onClick={() => handleForgetPassword()}
          color="inherit"
          variant="text"
        >
          Forgot your password
        </Button>
      </div>
    </div>
  );
};


export default Login;

const Input = styled(TextField)({
  width: "100%",
  "& label.Mui-focused": {
    color: "rgba(0,0,0,0.7)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "rgba(255,255,255,0.2)",
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255,255,255,0.9)",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255,255,255,0.9)",
      border: "none",
    },
    "& input": {
      color: "rgba(0,0,0,0.9)", // Text color with 50% opacity
    },
    "& label": {
      color: "rgba(0,0,0,0.9)", // Label color with 70% opacity for better readability
    },
  },
});
