import React, { useState } from "react";
import "./header.css";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import AvatarImg from "../assets/image/avatar.jpg";
import { useNavigate } from "react-router-dom";
import MyProfile from "../../views/myprofile/MyProfile";
import ChangePassword from "../../views/changepassword/ChangePassword";
// import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import { handleLogOut } from "../service/apis/user/User.api";
import Drawer from "@mui/material/Drawer";
import SidebarMenu from "../navbar/SidebarMenu";

const Header = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [OpenChangePassword, setOpenChangePassword] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openm, setOpenm] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenm(newOpen);
  };

  return (
    <>
      <Drawer
        open={openm}
        onClose={toggleDrawer(false)}
        sx={{
          width: 240, // Set the width of the drawer
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "300px", // Set the width of the drawer's paper component
            boxSizing: "border-box",
            backgroundColor: "rgba(255, 255, 255, 0.49)",
            backdropFilter: "blur(25px)",
          },
        }}
      >
        <SidebarMenu />
      </Drawer>
      <div className="header">
        <div>
          {
            // <div className="mail-icon-res">
            //   <IconButton
            //     onClick={() => navigate("/message")}
            //     aria-label="notifications"
            //   >
            //     <EmailIcon style={{ color: "white" }} />
            //   </IconButton>
            // </div>
          }
          <div className="menu-icon-res">
            <IconButton
              //   style={{ display: "none" }}
              onClick={toggleDrawer(true)}
              aria-label="notifications"
            >
              <MenuIcon style={{ color: "white" }} />
            </IconButton>
          </div>
        </div>

        <div className="avatar">
          {localStorage.getItem("name").toUpperCase()}
          <Avatar
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              cursor: "pointer",
            }}
            alt="Remy Sharp"
            src={AvatarImg}
            onClick={handleClick}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "&>.MuiPaper-root": {
                backgroundColor: "var(--glass) !important",
                backdropFilter: "var(--blurr) !important",
                top: "97px !important",
              },
            }}
          >
            <MenuItem onClick={() => setOpenProfile(true)}>Profile</MenuItem>
            <MenuItem onClick={() => setOpenChangePassword(true)}>
              Change Password
            </MenuItem>
            <MenuItem onClick={() => handleLogOut(navigate)}>Logout</MenuItem>
          </Menu>
        </div>

        <MyProfile open={openProfile} onClose={() => setOpenProfile(false)} />
        <ChangePassword
          open={OpenChangePassword}
          onHide={() => setOpenChangePassword(false)}
        />
      </div>
    </>
  );
};

export default Header;
