import { FormControlLabel, MenuItem, Select, styled, Switch, TextField, Tooltip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react'
import AddShift from './AddShift';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { getShift, updateShift } from '../../component/service/apis/shift/Shifts.api';
import { Typography } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';

const ShiftList = () => {
    const [editableRow, setEditableRow] = useState(null);
    const [editValue, setEditValue] = useState({
        shiftName: '',
        timeIn: '',
        timeOut: '',
        halfDay: '',
        assign_late_mark: '',
        approval_required: '',
        grace_time: '',
        status: ''
    });
    const [shiftList, setShiftList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    const handleShiftList = async () => {
        const res = await getShift();
        if (res) {
            setShiftList(res);
        }
    }

    useEffect(() => {
        handleShiftList();
    }, [])


    const handleUpdate = () => {
        setOpenPopup(true);
    }

    const handleEditValue = (idx, e) => {
        setEditValue({ ...editValue, [e.target.name]: e.target.value });
    }

    const handleEdit = (idx, values) => {
        setEditableRow(idx);
        setEditValue({
            shiftName: values.shiftName,
            timeIn: values.timeIn,
            timeOut: values.timeOut,
            halfDay: values.halfDay,
            assign_late_mark: values.assign_late_mark,
            approval_required: values.approval_required,
            grace_time: values.grace_time,
            status: values.status
        });
    }

    const handleSave = (index) => {
        const shiftId = shiftList[index].id;
        const data = {
            shiftName: editValue.shiftName,
            timeIn: editValue.timeIn,
            timeOut: editValue.timeOut,
            graceTime: editValue.grace_time,
            status: editValue.status,
            assignLateMark: editValue.assign_late_mark,
            approvalRequired: editValue.approval_required,
            halfDay: editValue.halfDay,
        };
        updateShift(shiftId, data).then((res) => {
            res === true && handleShiftList()
        })
        setEditableRow(null);
    }

    const body = (
        <div className='popup'>
            <Typography variant="h5" gutterBottom>
                Are you sure to Update Status?
            </Typography>
            <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                <Button sx={{ width: '100%', opacity: '.8' }} onClick={() => handleUpdate()} color='success' variant='contained'>Yes</Button>
                <Button sx={{ width: '100%' }} onClick={() => setOpenPopup(false)} color='error'>Cancel</Button>
            </ButtonGroup>
        </div>
    );


    const CustomToolbar = () => (
        <Tooltip title='Add Role'>
            <IconButton onClick={() => setOpen(true)}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => <CustomToolbar />,
    };

    const columns = [
        {
            name: "S.No",
            label: "S.No",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => <div>{dataIndex + 1}</div>,
            },
        },
        {
            name: "shiftName",
            label: "Shift Name",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            value={editValue.shiftName}
                            name='shiftName'
                            onChange={(e) => handleEditValue(dataIndex, e)}
                            size="small"
                            sx={{width:'8em'}}
                        />;
                    }
                    return <div>{shiftList[dataIndex].shiftName}</div>;
                }
            },
        },
        {
            name: "timeIn",
            label: "Start Time",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            type='time'
                            name='timeIn'
                            value={editValue.timeIn}
                            size='small'
                            onChange={(e) => handleEditValue(dataIndex, e)}
                        />;
                    }
                    return <div>{shiftList[dataIndex].timeIn}</div>;
                }
            },
        },
        {
            name: "timeOut",
            label: "End Time",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            type='time'
                            name='timeOut'
                            value={editValue.timeOut}
                            size='small'
                            onChange={(e) => handleEditValue(dataIndex, e)}
                        />;
                    }
                    return <div>{shiftList[dataIndex].timeOut}</div>;
                }
            },
        },
        {
            name: "halfDay",
            label: "Half Day Time",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            type='time'
                            name='halfDay'
                            value={editValue.halfDay}
                            size='small'
                            onChange={(e) => handleEditValue(dataIndex, e)}
                        />;
                    }
                    return <div>{shiftList[dataIndex].halfDay}</div>;
                }
            },
        },
        {
            name: "assign_late_mark",
            label: "Assign Late Mark",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <FormControlLabel
                            control={
                                <Switch
                                    name="assign_late_mark"
                                    checked={editValue.assign_late_mark}
                                    onChange={() => handleEditValue(dataIndex, { target: { name: 'assign_late_mark', value: !editValue.assign_late_mark } })}
                                />
                            }
                        />
                    }
                    return <div>{shiftList[dataIndex].assign_late_mark ? 'Yes' : 'No'}</div>;
                },
            },
        },
        {
            name: "approval_required",
            label: "Attendance Approval Required",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <FormControlLabel
                            control={
                                <Switch
                                    name="approval_required"
                                    checked={editValue.approval_required}
                                    onChange={() => handleEditValue(dataIndex, { target: { name: 'approval_required', value: !editValue.approval_required } })}
                                />
                            }
                        />
                    }
                    return <div>{shiftList[dataIndex].approval_required ? 'Yes' : 'No'}</div>;
                },
            },
        },
        {
            name: "grace_time",
            label: "Grace Time",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            type='number'
                            name='grace_time'
                            sx={{ width: '100px' }}
                            value={editValue.grace_time}
                            size='small'
                            onChange={(e) => handleEditValue(dataIndex, e)}
                        />;
                    }
                    return <div>{shiftList[dataIndex].grace_time} min</div>;
                }
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <Select
                        sx={{ width: '100px' }}
                        value={editValue.status}
                        name="status"
                        size='small'
                        onChange={(e) => handleEditValue(dataIndex, e)}
                    >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                    }
                    return <div>{shiftList[dataIndex].status}</div>;
                }
            },
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return (
                            <div>
                                <IconButton color='success' onClick={() => handleSave(dataIndex)}>
                                    <DoneIcon fontSize='small' />
                                </IconButton>
                                <IconButton color='error' onClick={() => setEditableRow(null)}>
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            </div>
                        );
                    }
                    return (
                        <div>
                            <IconButton color='primary' onClick={() => handleEdit(dataIndex, shiftList[dataIndex])}>
                                <EditIcon fontSize='small' />
                            </IconButton>
                            {/* <IconButton color='error'>
                                <DeleteIcon fontSize='small' />
                            </IconButton> */}
                        </div>
                    )
                },
            },
        }
    ]

    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={"Shifts"}
                data={shiftList}
                columns={columns}
                options={options}
            />
            <AddShift
                open={open}
                onHide={() => setOpen(false)}
                setOpen={setOpen}
                handleShiftList={handleShiftList}
            />
            <ResponsiveDialog
                open={openPopup}
                onHide={() => setOpenPopup(false)}
                Content={body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default ShiftList

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});
