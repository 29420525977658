import React, { useCallback, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import AvatarImg from '../../component/assets/image/avatar.jpg';
import { Avatar, styled, TextField, Tooltip, Typography } from '@mui/material';
import './leaves.css';
// import { getAllLeaves } from '../../component/service/APIs';
import { getAllLeaves } from '../../component/service/apis/leave/Leave.api';
import { Select } from '@mui/material';
import { Chip } from '@mui/material';
import { MenuItem } from '@mui/material';
import { FormControl } from '@mui/material';
import LeavesStatusSelect from './LeaveStatusSelect';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ApplyLeave from './ApplyLeave';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

const role = localStorage.getItem('role');

const filterMenu = [
    // { name: 'Today Attendance' },
    { name: 'This Month' },
    { name: '3 Months' },
    { name: '6 Months' },
    { name: 'Custom Date' },
    // { name: 'Attendance History' },
]


const getColor = (selected) => {
    let opacity = '0.5';
    switch (selected) {
        case 'pending':
            return 'rgba(255, 193, 7, ' + opacity + ')';
        case 'approved':
            return 'rgba(76, 175, 80, ' + opacity + ')';
        default:
            return 'rgba(244, 67, 54, ' + opacity + ')'; // Pending
    }
};

const getLeaveColorstatus = (selected) => {
    let opacity = '0.5';
    switch (selected) {
        case 'approved':
            return 'rgba(76, 175, 80, ' + opacity + ')';
        case 'rejected':
            return 'rgba(244, 67, 54, ' + opacity + ')';
        default:
            return 'rgba(255, 193, 7, ' + opacity + ')';
    }
};


const Leaves = () => {

    const [userList, setUserList] = useState([]);
    const [leaveStatus, setLeaveStatus] = useState('pending');
    const [open, setOpen] = useState(false);
    const [attendanceStatus, setAttendanceStatus] = useState('This Month');
    const [dateRange, setDateRange] = useState({ from: null, to: null });

    const handleChangeStatus = (e) => {
        setLeaveStatus(e.target.value)
    };

    const handleChangeDataFilter = (e) => {
        setAttendanceStatus(e.target.value);
        if (e.target.value !== 'Custom Date') {
            setDateRange({ from: null, to: null });
        }
    }

    const handleDateChange = (date, type) => {
        setDateRange(prev => ({ ...prev, [type]: date }));
    };


    const CustomToolbar = () => {
        return (
            <React.Fragment>
                <Tooltip title='Apply for leave'>
                    <IconButton onClick={() => setOpen(true)}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                <FormControl>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        label="Status"
                        variant="standard"
                        value={leaveStatus}
                        onChange={(e) => handleChangeStatus(e)}
                        renderValue={(selected) => (
                            <Chip label={selected} sx={{ backgroundColor: getColor(selected) }} />
                        )}
                    >
                        <MenuItem value='approved'>
                            <Chip label="Approved" sx={{ backgroundColor: 'rgba(76, 175, 80, .5)' }} />
                        </MenuItem>
                        <MenuItem value='pending'>
                            <Chip label="Pending" sx={{ backgroundColor: 'rgba(255, 193, 7, .5)' }} />
                        </MenuItem>
                        <MenuItem value='rejected'>
                            <Chip label="Rejected" sx={{ backgroundColor: 'rgba(244, 67, 54, .5)' }} />
                        </MenuItem>

                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        label="Status"
                        variant="standard"
                        value={attendanceStatus}
                        onChange={(e) => handleChangeDataFilter(e)}
                        renderValue={(selected) => (
                            <Chip label={selected} sx={{ backgroundColor: 'rgba(21, 64, 138, .5)', color: 'whitesmoke' }} />
                        )}
                    >
                        {
                            filterMenu.map(({ name }) => {
                                return <MenuItem key={name} value={name}>
                                    <Chip label={name} sx={{ backgroundColor: 'rgba(21, 64, 138, .5)', color: 'whitesmoke' }} />
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    };

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => <CustomToolbar />,
    };



    const handleLeaveList = useCallback(async () => {
        let res;
        switch (attendanceStatus) {
            case 'Last Month':
                const oneMonth = {
                    noOfMonths: 1
                }
                res = await getAllLeaves(leaveStatus, oneMonth);
                break;
            case '3 Months':
                const threeMonth = {
                    noOfMonths: 3
                }
                res = await getAllLeaves(leaveStatus, threeMonth);
                break;
            case '6 Months':
                const sixMonth = {
                    noOfMonths: 6
                }
                res = await getAllLeaves(leaveStatus, sixMonth);
                break;
            case 'Custom Date':
                if (dateRange.from && dateRange.to) {
                    const data = {
                        fromDate: dateRange.from,
                        toDate: dateRange.to
                    }
                    res = await getAllLeaves(leaveStatus, data);
                }
                break;
            default:
                const curMonth = {
                    noOfMonths: 0
                }
                res = await getAllLeaves(leaveStatus, curMonth);
                break;
        }
        if (res) {
            setUserList(res);
        }
    }, [leaveStatus, attendanceStatus, dateRange]);

    useEffect(() => {
        handleLeaveList();
    }, [handleLeaveList]);

    const columns = [
        {
            name: "userLeaveID",
            label: "leave Id",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "S.No",
            label: "S.No",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return <div>{dataIndex + 1}</div>;
                },
            },
        },
        {
            name: "name",
            label: "Employee",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const leaves = tableMeta.rowData;
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt={leaves[1]} src={leaves[11] || AvatarImg} style={{ marginRight: '20px' }} />
                            <div>
                                <Typography variant="body1">{leaves[2]}</Typography>
                                <Typography variant="body2" color="textSecondary">{leaves[3]}</Typography>
                            </div>
                        </div>
                    );
                }
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "role_name",
            label: "Role",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "position_name",
            label: "Position",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "leave_name",
            label: "Leave Type",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "leave_desc",
            label: "Leave Description",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "leave_from",
            label: "From",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return value.split("T")[0]; // Splits the ISO string and returns the date part
                }
            },
        },
        {
            name: "leave_to",
            label: "To",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return value.split("T")[0]; // Splits the ISO string and returns the date part
                }
            },
        },
        {
            name: "pemissionStatus",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const leaves = tableMeta.rowData;

                    return (
                        (role === 'super admin' || (role === 'admin' && value === 'Pending')) ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <LeavesStatusSelect status={leaves[10]} leaveID={leaves[0]} handleLeaveList={handleLeaveList} />
                            </div>
                            :
                            <Chip label={value} sx={{ backgroundColor: getLeaveColorstatus(value) }} />
                    );
                }
            },
        },
        {
            name: "profile_image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
    ];

    const title = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p>Leaves List</p>
        {attendanceStatus === 'Custom Date' && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                    <CustomInput
                        label="From"
                        value={dateRange.from}
                        onChange={(date) => handleDateChange(date, 'from')}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <CustomInput
                        label="To"
                        value={dateRange.to}
                        onChange={(date) => handleDateChange(date, 'to')}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </div>
            </LocalizationProvider>
        )}
    </div>



    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={title}
                data={userList}
                columns={columns}
                options={options}
            />
            <ApplyLeave
                open={open}
                onHide={() => setOpen(false)}
                handleLeaveList={handleLeaveList}
            />
        </React.Fragment>
    );
}

export default Leaves;

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});

const CustomInput = styled(DatePicker)({
    '&>.MuiOutlinedInput-root': {
        display: 'flex',
        alignItems: 'center',
        width: '10em !important',
        height: '3em !important',
    }
})