import { notifyError, notifySuccess } from "../../../notify/Notify";
import { postRequestWithFetch, deleteRequestWithFetch, getRequestWithFetch } from "../../FetchingMethod";

const addHoliday = async (body) => {
    try {
        const res = await postRequestWithFetch(`shift/holiday`, body);
        if (res && res.status === true) {
            notifySuccess({ Message: res.message });
            return res.status;
        } else {
            notifyError({ Message: res.error });
            return false;
        }
    } catch (err) {
        notifyError({ Message: err });
        return false;
    }
};

const deleteHoliday = async (id) => {
    try {
        const res = await deleteRequestWithFetch(`shift/holiday`, { id });
        if (res && res.status === true) {
            notifySuccess({ Message: res.message });
            return res.status;
        } else {
            notifyError({ Message: res.error });
            return false;
        }
    } catch (err) {
        notifyError({ Message: err });
        return false;
    }
};

const getHolidaysByMonth = async (month) => {
    try {
        const res = await getRequestWithFetch(`shift/holiday/${month}`);
        if (res && res.status === true) {
            return res.data;
        } else {
            notifyError({ Message: res.error });
            return [];
        }
    } catch (err) {
        notifyError({ Message: err });
        return [];
    }
};

export {
    addHoliday,
    deleteHoliday,
    getHolidaysByMonth
};
