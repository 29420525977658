import React, { useState } from "react";
import {
  Button,
  Chip,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { validatePassword } from "../../component/service/CommonFunctions";
import "./forgetpassword.css";
import { resetPassword } from "../../component/service/apis/user/User.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import { useLocation } from "react-router-dom";

const style = {
  width: "100%",
};

const ForgetPassword = (props) => {
  const location = useLocation();

  // Create a URLSearchParams object from the query string
  const queryParams = new URLSearchParams(location.search);

  // Fetch the token
  const forgotToken = queryParams.get("forgotToken");
  //   console.log(forgotToken);

  let passwordValidation;
  const [password, setPassword] = useState({
    oldpass: "",
    newpass: "",
    confirmpass: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const { token } = useParams(); // Extract the token from the URL
  //   const location = useLocation(); // Get the current location
  const navigate = useNavigate();

  // Extract everything after the token
  const pathAfterToken = location.pathname.split(`forget/${token}/`)[1] || "";

  // Combine the token and the path after the token
  const combinedString = token + "/" + pathAfterToken;

  //   console.log(combinedString);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //   console.log(password);

  if (password.oldpass) {
    passwordValidation = validatePassword(password.newpass);
  } else if (password.newpass) {
    passwordValidation = validatePassword(password.confirmpass);
  }

  const handleChangePassword = () => {
    combinedString && setPassword({ oldpass: combinedString });

    const data = {
      forgotToken: forgotToken,
      password: password.confirmpass,
    };

    resetPassword(data, navigate);

    // console.log(data);

    setPassword({
      oldpass: "",
      newpass: "",
      confirmpass: "",
    });

    // navigate("/login");
  };

  const Body = (
    <div className="login">
      <div className="loginContainer">
        <Typography
          style={{ margin: "1em 1em 2em 1em" }}
          variant="h5"
          gutterBottom
        >
          Forget password
        </Typography>

        <Tooltip
          title={passwordValidation?.message || ""}
          open={!!password.newpass}
          arrow
        >
          <Input
            id="outlined-basic"
            name="newpass"
            label="New Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            value={password.newpass}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            required
          />
        </Tooltip>

        <Input
          id="outlined-basic"
          name="confirmpass"
          label="Confirm New Password"
          type="password"
          variant="outlined"
          value={password.confirmpass}
          onChange={handleChange}
          required
        />

        <Button
          style={style}
          color="primary"
          onClick={() => handleChangePassword()}
          variant="contained"
          size="large"
        >
          Submit
        </Button>
        <Divider style={{ marginTop: "1em", width: "100%" }}>
          <Chip label="OR" />
        </Divider>
        <Button onClick={props.onHide} color="inherit" variant="text">
          Back to Login
        </Button>
      </div>
    </div>
  );

  return <React.Fragment>{Body}</React.Fragment>;
};

export default ForgetPassword;

const Input = styled(TextField)({
  width: "100%",
  "& label.Mui-focused": {
    color: "rgba(0,0,0,0.7)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      backgroundColor: "rgba(255,255,255,0.2)",
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255,255,255,0.9)",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255,255,255,0.9)",
      border: "none",
    },
    "& input": {
      color: "rgba(0,0,0,0.9)", // Text color with 50% opacity
    },
    "& label": {
      color: "rgba(0,0,0,0.9)", // Label color with 70% opacity for better readability
    },
  },
});
