import { notifyError, notifySuccess } from "../../../notify/Notify";
import { patchRequestWithFetch } from "../../FetchingMethod";

const updateUserRole = async (roleId, userId) => {
    const res = await patchRequestWithFetch(`role/user/update/${roleId}/${userId}`)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        res && notifyError({ Message: res.error });
    }
}

export { updateUserRole }