const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (!passwordRegex.test(password)) {
        return { message: 'Password must contain capital letter, small letter,special character and number', color: 'red' };
    }
};

const validateEmail = (email) => {
    // Define a regular expression for a valid email format
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!emailRegex.test(email)) {
        return { message: 'Invalid Email Format', color: 'rgba(252, 215, 215,1)' };
    }
};


export { validateEmail, validatePassword }