import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Chip, FormControl, Typography, Button, ButtonGroup } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import './leaves.css';
import { approval } from '../../component/service/apis/leave/Leave.api';

function LeavesStatusSelect(props) {

    const [status, setStatus] = useState(props.status);
    const [openPopup, setOpenPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(props.status);

    useEffect(() => {
        setStatus(props.status)
    }, [props.status])

    const handleStatusChange = (event) => {
        setNewStatus(event.target.value);
        setOpenPopup(true);
    };

    const handleConfirmation = () => {
        const data = {
            leaveid: props.leaveID,
            status: newStatus
        };
        setStatus(newStatus);
        approval(data); // Update the status on the server
        props.handleLeaveList();
        setOpenPopup(false);
    };

    const getColor = (selected) => {
        let opacity = '0.5';
        switch (selected) {
            case 'approved':
                return 'rgba(76, 175, 80, ' + opacity + ')';
            case 'rejected':
                return 'rgba(244, 67, 54, ' + opacity + ')';
            default:
                return 'rgba(255, 193, 7, ' + opacity + ')';
        }
    };

    const body = (
        <div className='popup'>
            <Typography variant="h5" gutterBottom>
                Are you sure to update Status?
            </Typography>
            <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                <Button sx={{ width: '100%', opacity: '.8' }} onClick={handleConfirmation} color='success' variant='contained'>Yes</Button>
                <Button sx={{ width: '100%' }} onClick={() => setOpenPopup(false)} color='error'>Cancel</Button>
            </ButtonGroup>
        </div>
    );

    if (localStorage.getItem('role') === 'user') {
        return <Chip label={status} sx={{ backgroundColor: getColor(status) }} />
    } else {
        return (
            <React.Fragment>
                <FormControl fullWidth>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        value={status}
                        label="Status"
                        variant="standard"
                        style={{ width: 'fit-content' }}
                        onChange={handleStatusChange}
                        renderValue={(selected) => (
                            <Chip label={selected} sx={{ backgroundColor: getColor(selected) }} />
                        )}
                    >
                        <MenuItem value='approved'>
                            <Chip label="Approve" sx={{ backgroundColor: 'rgba(76, 175, 80, .5)' }} />
                        </MenuItem>
                        <MenuItem value='rejected'>
                            <Chip label="Reject" sx={{ backgroundColor: 'rgba(244, 67, 54, .5)' }} />
                        </MenuItem>
                        <MenuItem value='pending' disabled>
                            <Chip label="Pending" sx={{ backgroundColor: 'rgba(255, 193, 7, .5)' }} />
                        </MenuItem>
                    </Select>
                </FormControl>

                <ResponsiveDialog
                    open={openPopup}
                    onHide={() => setOpenPopup(false)}
                    Content={body}
                    sx={{
                        '& .MuiDialog-paper': {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        }
                    }}
                />
            </React.Fragment>
        );
    }
}

export default LeavesStatusSelect;
