import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Tooltip,
    Legend
);

const ChartComponent = ({ type, data, options }) => {
    // Determine which chart type to render
    const ChartType = {
        'bar': Bar,
        'line': Line,
        'doughnut': Doughnut
    }[type] || Bar; // Default to Bar chart if type is not provided

    if (!ChartType) {
        return <p>Chart type is not supported</p>;
    }

    return (
        <ChartType data={data} options={options} />
    );
};

export default ChartComponent;
