import React, { useEffect, useState } from 'react';
import ChartComponent from '../../component/chart/ChartComponent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { getGraphData } from '../../component/service/apis/dashboard/dashboard.api';

const role = localStorage.getItem('role');

const UserChart = () => {
  const [graphData, setGraphData] = useState({});
  const [selectedRole, setSelectedRole] = useState(role === 'user' ? 'user' : 'user');

  useEffect(() => {
    getGraphData().then((res) => {
      setGraphData(res);
    });
  }, []);

  const handleChangeRole = (event) => {
    setSelectedRole(event.target.value);
  };

  const { userData = [], managerData = [], adminData = [], dayNames = [] } = graphData || {};

  let data = {
    labels: dayNames,
    datasets: [{
      label: '',
      data: [],
      backgroundColor: '',
      borderColor: '',
      fill: false,
    }],
  };

  switch (selectedRole) {
    case 'user':
      data.datasets[0].label = 'Users';
      data.datasets[0].data = userData;
      data.datasets[0].backgroundColor = 'rgba(255, 167, 85, 0.5)';
      data.datasets[0].borderColor = 'rgba(255, 167, 85, 1)';
      break;
    case 'admin':
      data.datasets[0].label = 'Admin';
      data.datasets[0].data = adminData;
      data.datasets[0].backgroundColor = 'rgba(180, 141, 211, 0.5)';
      data.datasets[0].borderColor = 'rgba(180, 141, 211, 1)';
      break;
    case 'manager':
      data.datasets[0].label = 'Managers';
      data.datasets[0].data = managerData;
      data.datasets[0].backgroundColor = 'rgba(104, 227, 101, 0.5)';
      data.datasets[0].borderColor = 'rgba(104, 227, 101, 1)';
      break;
    default:
      break;
  }

  const options = {
    // ... your existing options
    responsive: true,
  };

  const renderMenuItems = () => {
    let items = [<MenuItem value="user">User</MenuItem>]; // 'User' is available for all roles

    if (role === 'super admin' || role === 'admin') {
      items.push(<MenuItem value="manager">Manager</MenuItem>);
    }

    if (role === 'super admin') {
      items.push(<MenuItem value="admin">Admin</MenuItem>);
    }

    return items;
  };

  return (
    <React.Fragment>
      {/* ... existing content ... */}

      <div className="chart-header">
        {`${selectedRole.charAt(0).toUpperCase() + selectedRole.slice(1)} Attendance - Last 7 Days`}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={selectedRole}
            onChange={handleChangeRole}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {renderMenuItems()}
          </Select>
        </FormControl>
      </div>

      <ChartComponent type="line" data={data} options={options} />
    </React.Fragment>
  );
};

export default UserChart;