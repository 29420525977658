import React, { useState } from 'react'
import { Box, Button, Container, FormControl, TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { AddMessages } from '../../component/service/APIs';
// import { getLeaveType } from '../../component/service/apis/leave/leave.api';

const AddMessage = (props) => {

    const [data, setdata] = useState({
        subject: '',
        body: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setdata(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleAddMessage = () => {
        AddMessages(data).then((res) => {
            if (res) {
                props.handleMessage(localStorage.getItem('role'))
                props.onHide()
            }
        })
    }

    // useEffect(() => {
    //     const fetchLeaves = async () => {
    //         const res = await getLeaveType()
    //         console.log("hello", res);
    //     }
    //     props.open === true && fetchLeaves()
    // }, [props.open])


    const Body = <React.Fragment>
        <Container className='popup' sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
            }}>
                <p>
                    Write Message
                </p>
            </Box>
            <Box sx={{ width: '100%' }}>
                <FormControl sx={{ m: 1, display: 'flex', gap: '.5em' }}>
                    <TextField id="outlined-basic" label="Subject" variant="outlined" name='subject' type='text' onChange={handleChange} />
                    <TextareaAutosize name='body' onChange={handleChange} style={{ padding: '0px 5px', backgroundColor: 'inherit' }} placeholder="Message" minRows={4} />
                    <Button style={{ backgroundColor: 'rgba(21, 64, 138,.8)' }} size='large' onClick={() => handleAddMessage()} variant="contained">Send</Button>
                    <Button style={{ opacity: '.8' }} color='error' size='large' variant="outlined" onClick={props.onHide}>Cancel</Button>
                </FormControl>
            </Box>
        </Container>
    </React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default AddMessage