// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 1em;
}

@media screen and (max-widht:'786px') {
    .sidebartoggle {
        justify-content: space-between;
    }
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    color: white;
    /* font-size: 1.1em; */
    /* border: 1px solid brown; */
}`, "",{"version":3,"sources":["webpack://./src/component/header/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;AACjC","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 100%;\n    padding: 1em;\n}\n\n@media screen and (max-widht:'786px') {\n    .sidebartoggle {\n        justify-content: space-between;\n    }\n}\n\n.avatar {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 1em;\n    color: white;\n    /* font-size: 1.1em; */\n    /* border: 1px solid brown; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
