import { Chip, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { updateBucket } from '../../component/service/APIs';

const EditableBucket = ({ value, userID, onUpdate, handleUserList }) => {

    const [isEditing, setEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);

    const handleChipClick = () => {
        setEditing(true);
    };

    const handleTextFieldBlur = () => {
        setEditing(false);
        const Data = {
            editedValue: editedValue,
            userID: userID
        }
        updateBucket(Data).then((res) => {
            res && handleUserList();
        })
        onUpdate(editedValue);
    };

    const handleInputChange = (e) => {
        setEditedValue(e.target.value);
    };

    return (
        <React.Fragment>
            {isEditing ? (
                <TextField
                    value={editedValue}
                    onBlur={handleTextFieldBlur}
                    onChange={handleInputChange}
                    autoFocus
                />
            ) : (
                <Tooltip title="Click to update bucket" arrow>
                    <Chip label={value} onClick={handleChipClick} />
                </Tooltip>
            )}
        </React.Fragment>
    );
};

export default EditableBucket;
