import * as React from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'

const newTheme = (theme) => createTheme({
    ...theme,
    components: {
        MuiPickersToolbar: {
            styleOverrides: {
                root: {
                    color: '#ad1457',
                    borderRadius: 20,
                    borderWidth: 0,
                    borderColor: '#e91e63',
                    border: '0px solid',
                    backgroundColor: '#f8bbd0',
                }
            }
        }
    }
})

export default function DateCalender() {
    // Initialize the state with a Day.js object representing the current date
    const [date, setDate] = useState(dayjs());

    const CustomToolbar = () => <React.Fragment></React.Fragment>;

    return (
        <div style={{ borderRadius: '1.4em', overflow: 'hidden',minWidth:'31em', }}>
            <ThemeProvider theme={newTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StyledStaticDatePicker
                        orientation="landscape"
                        // displayStaticWrapperAs="desktop"
                        value={date} // Use the Day.js object
                        onChange={(newDate) => setDate(newDate)}
                        readOnly // Optional: Makes the date picker read-only if selection is not needed
                        ToolbarComponent={CustomToolbar}
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
}


const StyledStaticDatePicker = styled(StaticDatePicker)(({ theme }) => ({
    padding: '1rem 1em',
    // minWidth:'10em',
    height: '100%',
    '.MuiDialogActions-root': {
        display: 'none',
    },
}));
