import React, { useState, useEffect } from 'react';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { Button, FormControl, Box, TextField, Autocomplete } from '@mui/material';
import { assignShiftToUser, getShift } from '../../component/service/apis/shift/Shifts.api';

const ShiftAssignToUser = (props) => {
    const [selectedShift, setSelectedShift] = useState(null);
    const [shiftList, setShiftList] = useState([]);

    useEffect(() => {
        handleShiftList();
    }, []);

    const handleShiftList = async () => {
        const res = await getShift();
        if (res) {
            setShiftList(res);
        }
    };

    // Handle form submission
    const handleSubmit = () => {
        if(!props.date){
            alert('Please select a valid date.')
        }

        const data = {
            userId: props.userid,
            shiftId: selectedShift?.id,
            date: props.date
        }

        assignShiftToUser(data).then(res => {
            if (res) {
                props.fetchshifts(props.currentstartdate, props.currentenddate);
                props.setisopen(false);
            }
        });
    };

    const content = (
        <React.Fragment>
            {/* Shift Selection */}
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id="shift-select-autocomplete"
                    options={shiftList}
                    getOptionLabel={(shift) => `${shift.shiftName} (${shift.timeIn.slice(0, 5)} - ${shift.timeOut.slice(0, 5)})`}
                    value={selectedShift}
                    onChange={(event, newValue) => {
                        setSelectedShift(newValue); // Store selected shift
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {`${option.shiftName} (${option.timeIn.slice(0, 5)} - ${option.timeOut.slice(0, 5)})`}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Shift" placeholder="Choose a shift" />
                    )}
                />
            </FormControl>

            {/* Date Range Selection */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginTop: 2 }}>
                <TextField
                    label="Shift Assign Date"
                    type="date"
                    value={props.date}
                    // onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
            </Box>

            {/* Submit Button */}
            <Button variant="contained" color="primary" fullWidth sx={{ marginTop: 3 }} onClick={handleSubmit}>
                Assign Shift
            </Button>
            {/* Cancel Button */}
            <Button variant="outlined" color="error" fullWidth sx={{ marginTop: 3 }} onClick={props.onclose}>
                Cancel
            </Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <ResponsiveDialog
                {...props}
                Content={content}
            />
        </React.Fragment>
    );
};

export default ShiftAssignToUser;
