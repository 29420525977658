import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { getDocumentsList } from '../../component/service/apis/documents/documents.api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function SelectDocuments(props) {
    const { setDocsName, docsName = [] } = props
    const theme = useTheme();
    const [docsList, setDocsList] = React.useState([]);
    const handleDocumentsList = async () => {
        const res = await getDocumentsList();
        if (res) {
            setDocsList(res);
        }
    }

    React.useEffect(() => {
        handleDocumentsList();
    }, [])

    const getDocNameById = (id) => {
        const doc = docsList.find(doc => doc.id === id);
        return doc ? doc.name : '';
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setDocsName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    return (
        <FormControl size='small' className='TextField' >
            <InputLabel id="demo-multiple-chip-label">Required Docs</InputLabel>
            <Select
                className='TextField'
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={docsName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Required Docs" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip size='small' key={value} label={getDocNameById(value)} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {docsList.map((doc) => (
                    <MenuItem
                        key={doc.name} // Assuming doc is a string. If it's an object, you might need doc.id or similar
                        value={doc.id}
                        style={getStyles(doc, doc.name, theme)}
                    >
                        {doc.name} {/* If doc is an object, you might need doc.name or similar */}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}