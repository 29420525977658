import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Chip, FormControl, Typography, Button, ButtonGroup } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import './attendance.css';
import { updateAttendanceStatus } from '../../component/service/apis/attendance/attendance.api';

function StatusSelect(props) {
    const [status, setStatus] = useState(props.status);
    const [openPopup, setOpenPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(props.status);

    useEffect(() => {
        setStatus(props.status);
    }, [props.status]);

    const handleStatusChange = (event) => {
        setNewStatus(event.target.value);
        setOpenPopup(true);
    };

    const handleConfirmation = () => {
        setStatus(newStatus);
        updateAttendanceStatus(props.userId, newStatus); // Update the status on the server
        setOpenPopup(false);
    };

    const getColor = (selected) => {
        let opacity = '0.5';
        switch (selected) {
            case 'present':
                return 'rgba(76, 175, 80, ' + opacity + ')';
            case 'absent':
                return 'rgba(244, 67, 54, ' + opacity + ')';
            case 'leave':
                return 'rgba(63, 81, 181, ' + opacity + ')'; // Color for Leave status
            case 'LWP':
                return 'rgba(172, 225, 230, ' + opacity + ')'; // Color for Leave status
            case 'late':
                return 'rgba(255, 87, 34, ' + opacity + ')'; // Color for Leave status
            default:
                return 'rgba(255, 193, 7, ' + opacity + ')'; // Default color for Pending
        }
    };

    const body = (
        <div className='popup'>
            <Typography variant="h5" gutterBottom>
                Are you sure to update Status?
            </Typography>
            <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                <Button sx={{ width: '100%', opacity: '.8' }} onClick={handleConfirmation} color='success' variant='contained'>Yes</Button>
                <Button sx={{ width: '100%' }} onClick={() => setOpenPopup(false)} color='error'>Cancel</Button>
            </ButtonGroup>
        </div>
    );

    if (localStorage.getItem('role') === 'user') {
        return <Chip label={status} sx={{ backgroundColor: getColor(status) }} />
    } else {
        return (
            <React.Fragment>
                <FormControl fullWidth>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        value={status}
                        label="Status"
                        variant="standard"
                        style={{ width: 'fit-content' }}
                        onChange={handleStatusChange}
                        renderValue={(selected) => (
                            <Chip label={selected} sx={{ backgroundColor: getColor(selected) }} />
                        )}
                    >
                        <MenuItem value='present'>
                            <Chip label="Present" sx={{ backgroundColor: 'rgba(76, 175, 80, .5)' }} />
                        </MenuItem>
                        <MenuItem value='absent'>
                            <Chip label="Absent" sx={{ backgroundColor: 'rgba(244, 67, 54, .5)' }} />
                        </MenuItem>
                        <MenuItem value='leave'>
                            <Chip label="Leave" sx={{ backgroundColor: 'rgba(63, 81, 181, .5)' }} />
                        </MenuItem>
                        <MenuItem value='pending'>
                            <Chip label="Pending" sx={{ backgroundColor: 'rgba(255, 193, 7, .5)' }} />
                        </MenuItem>
                        <MenuItem value='LWP'>
                            <Chip label="LWP" sx={{ backgroundColor: 'rgba(188, 202, 130, .5)' }} />
                        </MenuItem>
                        <MenuItem value='late'>
                            <Chip label="late" sx={{ backgroundColor: 'rgba(255, 87, 34, .5)' }} />
                        </MenuItem>
                    </Select>
                </FormControl>

                <ResponsiveDialog
                    open={openPopup}
                    onHide={() => setOpenPopup(false)}
                    Content={body}
                    sx={{
                        '& .MuiDialog-paper': {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        }
                    }}
                />
            </React.Fragment>
        );
    }
}

export default StatusSelect;
