import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                // open={props.open}
                // onClose={props.onHide}
                TransitionComponent={Transition}
                {...props}
            >
                {props.body}
            </Dialog>
        </React.Fragment>
    );
}
