import React, { useEffect, useState } from "react";
import ResponsiveCards from "../../component/card/ResponsiveCards";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PersonIcon from "@mui/icons-material/Person";
import "./dashboard.css";
import UserChart from "../users/UserChart";
import styled from "@emotion/styled";
import DateCalender from "../../component/calender/DateCalender";
import { getDashboardCards } from "../../component/service/apis/dashboard/dashboard.api";
// import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const colors = ["rgb(255,167,85)", "rgb(104,227,101)", "rgb(180,141,211)"];

const iconMapping = {
  usersOnLeave: (
    <ExitToAppIcon sx={{ backgroundColor: "none" }} fontSize="medium" />
  ),
  totalAdmin: (
    <EventAvailableIcon sx={{ backgroundColor: "none" }} fontSize="medium" />
  ),
  totalManager: (
    <PersonIcon sx={{ backgroundColor: "none" }} fontSize="medium" />
  ),
  totalUser: <PersonIcon sx={{ backgroundColor: "none" }} fontSize="medium" />,
  sick: <PersonIcon sx={{ backgroundColor: "none" }} fontSize="medium" />,
  // Add more mappings as needed for different fields
};

const Dashboard = () => {
  const [cardsData, setCardsData] = useState([]);
  // const [userData, setUserData] = useState([]);
  // const [userType, setUserType] = useState("all");

  useEffect(() => {
    getDashboardCards().then((res) => {
      processDashboardData(res);
    });
    // fetchUserData(userType);
  }, []);

  const processDashboardData = (data) => {
    const newCardData = [];
    for (const key in data) {
      if (data[key] !== null && key !== "userId" && iconMapping[key]) {
        newCardData.push({
          Icon: iconMapping[key],
          Count: data[key].toString(),
          title: key.replace(/([A-Z])/g, " $1").trim(), // Convert camelCase to regular text
        });
      }
    }
    setCardsData(newCardData);
  };

  // const fetchUserData = (type) => {
  //   // Simulating API call
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       // This is mock data. In a real scenario, you'd fetch this from your API
  //       const data = [
  //         { date: "2023-06-01", absentCount: 10, presentCount: 20 },
  //         { date: "2023-06-02", absentCount: 15, presentCount: 25 },
  //         { date: "2023-06-03", absentCount: 20, presentCount: 30 },
  //       ];
  //       setUserData(data);
  //       resolve(data);
  //     }, 1000);
  //   });
  // };

  // const handleUserTypeChange = (event) => {
  //   setUserType(event.target.value);
  // };

  return (
    <div className="dashboard">
      <div className="container">
        {cardsData.map(({ Icon, Count, title }, index) => (
          <React.Fragment key={index}>
            <ResponsiveCards
              Icon={Icon}
              Count={Count}
              title={title}
              bgcolor={colors[index % colors.length]}
            />
          </React.Fragment>
        ))}
      </div>
      <ChartDiv>
        <div className='user-chart-container'>
          <UserChart />
        </div>
        <DateCalender />
      </ChartDiv>
    </div>
  );
};

export default Dashboard;

const ChartDiv = styled("div")({
  display: "grid",
  gridTemplateColumns: "53% 44%",
  gap: "1em",
  borderRadius: "1em",
  // border: '3px solid Red',
  overflow: "auto",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  padding: "1em",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "1fr", // Stacks the columns into a single column
  },

  // Media query for mobile devices
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "1fr", // Stacks the columns into a single column
    padding: "0.5em", // Adjust padding for smaller screens
  },
});
