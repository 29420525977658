import React, { useCallback, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import AvatarImg from '../../component/assets/image/avatar.jpg';
import { Avatar, styled, Tooltip, Typography } from '@mui/material';
import './user.css'
import { Button } from '@mui/material';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import { Chip } from '@mui/material';
import { MenuItem } from '@mui/material';
import { getUserList, handleAssignUser, handleAssignUserRemove } from '../../component/service/apis/user/User.api';

const filterMenu = [
    { name: 'assigned' },
    { name: 'not assigned' },
]

const OnlyUserList = (props) => {


    const [userList, setUserList] = useState([]);

    const [assignedUser, setAssignedUser] = useState('assigned')

    const handleUserList = useCallback(async () => {
        const data = {
            userId: props.managerId,
            status: assignedUser
        }
        let res = await getUserList(data);

        res && setUserList(res)
    }, [assignedUser, props.managerId]);

    useEffect(() => {
        handleUserList();
    }, [handleUserList])


    const handleAssign = (selectedRows, displayData, _setSelectedRows) => {
        const userids = selectedRows.data.map(row => {
            const dataIndex = row.dataIndex; // dataIndex is the index in displayData
            return displayData[dataIndex].data[0]; // Assuming userID is at index 0 in each row
        });
        const assignData = {
            managerId: props.managerId,
            userId: userids
        }
        if (assignedUser === 'assigned') {
            handleAssignUserRemove(assignData)
        } else {
            handleAssignUser(assignData)
        }
    }

    const columns = [
        {
            name: "id",
            label: "UserID",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "employeeID",
            label: "Employee ID",
            options: {
                sort: false,
            },
        },
        {
            name: "name",
            label: "Employee",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const employee = tableMeta.rowData;

                    return (
                        <React.Fragment>
                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <Avatar alt={employee[1]} src={employee[9] || AvatarImg} style={{ marginRight: '20px' }} />
                                <div>
                                    <Typography variant="body1">{employee[2]}</Typography>
                                    <Typography variant="body2" color="textSecondary">{employee[3]}</Typography>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
        {
            name: "roleName",
            label: "Role",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "positionName",
            label: "Position",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "departmentName",
            label: "Department Name",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "gender",
            label: "Gender",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false
            },
        },
    ];





    const CustomToolbar = () => {
        return (
            <React.Fragment>
                <FormControl>
                    <Select
                        labelId="status-select-label"
                        id="status-select"
                        label="Status"
                        variant="standard"
                        value={assignedUser}
                        onChange={(e) => setAssignedUser(e.target.value)}
                        renderValue={(selected) => (
                            <Chip label={selected} sx={{ backgroundColor: 'rgba(21, 64, 138, .5)', color: 'whitesmoke' }} />
                        )}
                    >
                        {
                            filterMenu.map(({ name }) => {
                                return <MenuItem key={name} value={name}>
                                    <Chip label={name} sx={{ backgroundColor: 'rgba(21, 64, 138, .5)', color: 'whitesmoke' }} />
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        )
    }

    const CustomToolbarSelect = (selectedRows, displayData, setSelectedRows) => (
        <Tooltip title="Assign">
            <Button variant='outlined' onClick={() => handleAssign(selectedRows, displayData, setSelectedRows)}>
                {assignedUser === 'assigned' ? 'Remove Users' : 'Assign users'}
            </Button>
        </Tooltip>
    );

    const options = {
        filterType: 'checkbox',
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        tableBodyHeight: '28em',
        selectableRows: 'multiple',
        customToolbar: () => <CustomToolbar />,
        textLabels: {
            // ... other text labels
            selectedRows: {
                text: "row(s) selected",
                delete: "Assign",  // Change the label to "Assign"
                deleteAria: "Assign Selected Rows",
            },
        },
        customToolbarSelect: CustomToolbarSelect,

    };

    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={"Employees List"}
                data={userList}
                columns={columns}
                options={options}
            />
        </React.Fragment>
    );
}

export default OnlyUserList;

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});