import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../views/dashboard/Dashboard";
import Attendance from "../../views/attendance/Attendance";
import SidebarMenu from "../navbar/SidebarMenu";
import Header from "../header/Header";
import Message from "../../views/message/Message";
import User from "../../views/users/User";
import Leaves from "../../views/leaves/Leaves";
import Manager from "../../views/manager/Manager";
import Role from "../../views/role/Role";
import Position from "../../views/position/Position";
import Department from "../../views/department/Department";
import Admin from "../../views/admin/Admin";
import LeaveType from "../../views/leaves/LeaveTypeList";
import Documents from "../../views/documents/Documents";
import Shifts from "../../views/shift/Shifts";
import UserShifts from "../../views/shift/UserShifts";
import Holiday from "../../views/holiday/holiday";

const Layout = () => {
  return (
    <React.Fragment>
      <div className="app">
        <div className="sidebar">
          <SidebarMenu />
        </div>
        <div className="appbody">
          <div className="appheader">
            <Header />
          </div>
          <div className="appComponent">
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/attendance" element={<Attendance />} />
              <Route path="/message" element={<Message />} />
              <Route path="/leaves" element={<Leaves />} />
              {(localStorage.getItem("role") === "manager" ||
                localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "super admin") && (
                <Route exact path="/users" element={<User />} />
              )}
              {(localStorage.getItem("role") === "admin" ||
                localStorage.getItem("role") === "super admin") && (
                <Route exact path="/managers" element={<Manager />} />
              )}
              {localStorage.getItem("role") === "super admin" && (
                <React.Fragment>
                  <Route exact path="/admins" element={<Admin />} />
                  <Route exact path="/roles" element={<Role />} />
                  <Route exact path="/position" element={<Position />} />
                  <Route exact path="/department" element={<Department />} />
                  <Route exact path="/leavesType" element={<LeaveType />} />
                  <Route exact path="/documents" element={<Documents />} />
                  <Route exact path="/shifts" element={<Shifts />} />
                  <Route exact path="/assign-shift" element={<UserShifts />} />
                  <Route exact path="/holidays" element={<Holiday />} />
                </React.Fragment>
              )}
            </Routes>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Layout;
