import React from 'react'
import AdminList from './AdminList'

const Admin = () => {
    return (
        <div>
            <AdminList />
        </div>
    )
}

export default Admin