import React, { useState } from 'react'
import { Button, TextField, Tooltip, Typography, styled, IconButton } from '@mui/material';
import { validatePassword } from '../../component/service/CommonFunctions';
import './changepassword.css';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { notifyWarning } from '../../component/notify/Notify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { changepassword } from '../../component/service/apis/user/User.api';
let passwordValidation;

const style = {
    width: '100%',
}

const ChangePassword = (props) => {

    const [showPassword, setShowPassword] = useState(false);

    const [Password, setPassword] = useState({
        oldpass: '',
        newpass: '',
        confirmpass: ''
    })

    if (Password.oldpass) {
        passwordValidation = validatePassword(Password.oldpass);
    } else if (Password.newpass) {
        passwordValidation = validatePassword(Password.newpass);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPassword(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    // Login data
    const handleLogin = () => {
        if (Password.newpass === Password.confirmpass) {
            const data = {
                currentPassword: Password.oldpass,
                newPassword: Password.newpass,
                Confirnpassword: Password.confirmpass,
            }
            changepassword(data);
        } else {
            notifyWarning({ Message: 'New Password and Confirm Password Mismatch' })
        }
    }

    const Body = <div>
        <div className='loginContainer'>
            <Typography style={{ margin: '0 0 1em 0' }} variant="h5" gutterBottom>
                Change Password
            </Typography>

            <Tooltip title={passwordValidation?.message || ''} open={!!(Password.oldpass)} arrow>
                <Input id="outlined-basic" name='oldpass' label="Current Password" type='password' variant="outlined" value={Password.oldpass} onChange={handleChange} required />
            </Tooltip>

            <Tooltip title={passwordValidation?.message || ''} open={!!(Password.newpass)} arrow>
                <Input id="outlined-basic" name='newpass' label="New Password" type={showPassword ? 'text' : 'password'} variant="outlined" value={Password.newpass} onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setShowPassword(showPassword === true ? false : true)} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        )
                    }}
                    required />
            </Tooltip>

            <Input id="outlined-basic" name='confirmpass' label="Confirm New Password" type='password' variant="outlined" value={Password.confirmpass} onChange={handleChange} required />

            <Button style={style} color="primary" onClick={() => handleLogin()} variant="contained" size="large">Change</Button>

            <Button onClick={props.onHide} color='warning' variant="text">Cancel</Button>
        </div>
    </div>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default ChangePassword

const Input = styled(TextField)({
    width: '100%',
    '& label.Mui-focused': {
        color: 'rgba(0,0,0,0.7)',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            backgroundColor: 'rgba(255,255,255,0.2)',
            border: 'none'
        },
        '&:hover fieldset': {
            borderColor: 'rgba(255,255,255,0.9)',
            border: 'none'
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(255,255,255,0.9)',
            border: 'none'
        },
        '& input': {
            color: 'rgba(0,0,0,0.9)', // Text color with 50% opacity
        },
        '& label': {
            color: 'rgba(0,0,0,0.9)', // Label color with 70% opacity for better readability
        },
    },
})
