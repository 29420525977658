import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { styled, Tooltip, IconButton, TextField } from '@mui/material';
import './role.css';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddRole from './AddRole';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { deleteRole, getRole, updateRole } from '../../component/service/apis/role/Role.api';

const Role = () => {
    const [roleList, setRoleList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [indexRole, setIndexRole] = useState('');

    const body = (
        <div className='popup'>
            <Typography variant="h5" gutterBottom>
                Are you sure to delete Role?
            </Typography>
            <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                <Button sx={{ width: '100%', opacity: '.8' }} onClick={() => handleDelete()} color='success' variant='contained'>Yes</Button>
                <Button sx={{ width: '100%' }} onClick={() => setOpenPopup(false)} color='error'>Cancel</Button>
            </ButtonGroup>
        </div>
    );

    const handleRoleList = async () => {
        const res = await getRole();
        if (res) {
            setRoleList(res);
        }
    }



    const handleChangeDelete = (index) => {
        setIndexRole(index);
        setOpenPopup(true)
    }

    useEffect(() => {
        handleRoleList();
    }, [])

    const handleEdit = (index, role) => {
        setEditableRow(index);
        setEditValue(role);
    };

    const handleSave = (index) => {
        const roleId = roleList[index].id;
        const data = {
            roleId: roleId,
            roleName: editValue
        }
        updateRole(data).then((res) => {
            console.log(res);
            res === true && handleRoleList()
        })
        setEditableRow(null);
    };

    const handleDelete = () => {
        setOpenPopup(false)
        const roleId = roleList[indexRole].id;
        const data = {
            roleId: roleId,
        }
        deleteRole(data).then((res) => {
            res === true && handleRoleList()
        })
        handleRoleList()
    }

    const handleCancel = () => {
        setEditableRow(null);
    };

    const columns = [
        {
            name: "S.No",
            label: "S.No",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => <div>{dataIndex + 1}</div>,
            },
        },
        {
            name: "role_name",
            label: "Role",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            size="small"
                        />;
                    }
                    return <div>{roleList[dataIndex].role_name}</div>;
                },
            },
        },
        // {
        //     name: "createdAt",
        //     label: "created at",
        //     options: {
        //         filter: false,
        //         sort: false,
        //     },
        // },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return (
                            <div>
                                <IconButton color='success' onClick={() => handleSave(dataIndex)}>
                                    <DoneIcon fontSize='small' />
                                </IconButton>
                                <IconButton color='error' onClick={handleCancel}>
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            </div>
                        );
                    }
                    return (
                        <div>
                            <IconButton onClick={() => handleEdit(dataIndex, roleList[dataIndex].role_name)}>
                                <EditIcon fontSize='small' />
                            </IconButton>
                            <IconButton color='error' onClick={() => handleChangeDelete(dataIndex)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </div>
                    );
                },
            },
        },
    ];

    const CustomToolbar = () => (
        <Tooltip title='Add Role'>
            <IconButton onClick={() => setOpen(true)}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => <CustomToolbar />,
    };

    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={"Role List"}
                data={roleList}
                columns={columns}
                options={options}
            />
            <AddRole
                open={open}
                onHide={() => setOpen(false)}
                setOpen={setOpen}
                handleRoleList={handleRoleList}
            />
            <ResponsiveDialog
                open={openPopup}
                onHide={() => setOpenPopup(false)}
                Content={body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    );
}

export default Role;

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});
