import React, { useState, useEffect } from 'react';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { getUserList } from '../../component/service/apis/user/User.api';
import { Button, Chip, FormControl, Box, TextField, Avatar, Checkbox, Autocomplete } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import dayjs from 'dayjs';
import { assignShiftToUsers, getShift } from '../../component/service/apis/shift/Shifts.api';

const AssignShifts = (props) => {
    const [userList, setUserList] = useState([]); // List of users fetched from the API
    const [selectedUsers, setSelectedUsers] = useState([]); // Selected users' IDs
    const [selectedShift, setSelectedShift] = useState(null); // Selected shift
    const [shiftList, setShiftList] = useState([]); // List of shifts fetched from the API
    const [startDate, setStartDate] = useState(''); // Start date for the range
    const [endDate, setEndDate] = useState(''); // End date for the range

    useEffect(() => {
        handleUserList(); // Fetch users when the component mounts
        handleShiftList(); // Fetch shifts when the component mounts
    }, []);

    const handleUserList = async () => {
        const res = await getUserList();
        if (res) {
            setUserList(res);
        }
    };

    const handleShiftList = async () => {
        const res = await getShift();
        if (res) {
            setShiftList(res); // Store the fetched shifts
        }
    };

    // Calculate the date range between start and end date
    const generateDateRange = (start, end) => {
        const dateArray = [];
        let currentDate = dayjs(start);
        const stopDate = dayjs(end);

        while (currentDate.isBefore(stopDate) || currentDate.isSame(stopDate)) {
            dateArray.push(currentDate.format('YYYY-MM-DD'));
            currentDate = currentDate.add(1, 'day');
        }

        return dateArray;
    };

    // Handle form submission
    const handleSubmit = () => {
        // Validate date range
        if (!startDate || !endDate || dayjs(startDate).isAfter(dayjs(endDate))) {
            alert('Please select a valid date range.');
            return;
        }

        // Generate all the dates between startDate and endDate
        const formattedDateRange = generateDateRange(startDate, endDate);

        const data = {
            userId: selectedUsers.map((user) => user.id), // Map selected user IDs
            shiftId: selectedShift?.id, // Shift ID
            dateRange: formattedDateRange,
        };

        assignShiftToUsers(data).then(res => {
            if (res) {
                alert('Shift assigned successfully');
            }
        });

        // Here you would typically send the data to the API
    };

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    const content = (
        <React.Fragment>
            {/* User Selection */}
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={userList}
                    disableCloseOnSelect
                    getOptionLabel={(user) => `${user.name} (${user.departmentName})`}
                    value={selectedUsers}
                    onChange={(event, newValue) => {
                        setSelectedUsers(newValue); // Store selected users
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {/* Display avatar with fallback for name initial */}
                            <Avatar src={option.image || ''} alt={option.name} style={{ marginRight: 8 }}>
                                {option.image ? '' : option.name.charAt(0).toUpperCase()}
                            </Avatar>
                            <div>
                                <div>{`${option.name} (${option.departmentName})`}</div>
                                <div style={{ fontSize: '0.85em', color: '#888' }}>{option.email}</div>
                            </div>
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Users" placeholder="Choose users" />
                    )}
                    renderTags={(selected, getTagProps) =>
                        selected.map((option, index) => (
                            <Chip
                                avatar={<Avatar src={option.image || ''}>{option.image ? '' : option.name.charAt(0).toUpperCase()}</Avatar>}
                                label={
                                    <div>
                                        <span>{option.name} ({option.departmentName})</span>
                                    </div>
                                }
                                {...getTagProps({ index })}
                                key={option.id}
                            />
                        ))
                    }
                />
            </FormControl>

            {/* Shift Selection */}
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id="shift-select-autocomplete"
                    options={shiftList}
                    getOptionLabel={(shift) => `${shift.shiftName} (${shift.timeIn.slice(0, 5)} - ${shift.timeOut.slice(0, 5)})`}
                    value={selectedShift}
                    onChange={(event, newValue) => {
                        setSelectedShift(newValue); // Store selected shift
                    }}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {`${option.shiftName} (${option.timeIn.slice(0, 5)} - ${option.timeOut.slice(0, 5)})`}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Shift" placeholder="Choose a shift" />
                    )}
                />
            </FormControl>

            {/* Date Range Selection */}
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginTop: 2 }}>
                <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
                <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                />
            </Box>

            {/* Submit Button */}
            <Button variant="contained" color="primary" fullWidth sx={{ marginTop: 3 }} onClick={handleSubmit}>
                Assign Shift
            </Button>
            {/* Cancel Button */}
            <Button variant="outlined" color="error" fullWidth sx={{ marginTop: 3 }} onClick={props.onClose}>
                Cancel
            </Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <ResponsiveDialog
                {...props}
                Content={content}
            />
        </React.Fragment>
    );
};

export default AssignShifts;
