// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    background-color: var(--dialog_glass);
    -webkit-backdrop-filter: var(--blurr);
            backdrop-filter: var(--blurr);
    padding: 2em 4em 2em 4em;
    border-radius: var(--g_radius);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .7em;
    min-width: 40%;
    color: rgba(21, 64, 138,.8);
}`, "",{"version":3,"sources":["webpack://./src/views/attendance/attendance.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,qCAA6B;YAA7B,6BAA6B;IAC7B,wBAAwB;IACxB,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;IACT,cAAc;IACd,2BAA2B;AAC/B","sourcesContent":[".popup {\n    background-color: var(--dialog_glass);\n    backdrop-filter: var(--blurr);\n    padding: 2em 4em 2em 4em;\n    border-radius: var(--g_radius);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap: .7em;\n    min-width: 40%;\n    color: rgba(21, 64, 138,.8);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
