import React from 'react'
import UserList from './UserList'

const User = () => {
    return (
        <div>
            <UserList />
        </div>
    )
}

export default User