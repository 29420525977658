import { notifyError, notifySuccess } from "../notify/Notify";
import { deleteRequestWithFetch, getRequestWithFetch, postRequestWithFetch, putRequestWithFetch } from "./FetchingMethod";

// const role = localStorage.getItem('role');

// const login = async (data, navigate) => {

//     try {
//         const res = await postRequestWithFetch(`auth/login`, data)
//         if (res.status === true) {
//             localStorage.setItem('token', res.token);
//             localStorage.setItem('role', res.role);
//             // localStorage.setItem('role', "user");
//             localStorage.setItem('name', res.userName);
//             await navigate("/")
//             window.location.reload()
//         } else {
//             res && notifyError({ Message: res.error })
//         }
//     } catch (err) {
//         notifyError({ Message: "Please check your login Id and Password" })
//         // handleLogOut();
//     }

// }

// const handleLogOut = async (navigate) => {
//     localStorage.removeItem('token');
//     localStorage.removeItem('role');
//     localStorage.removeItem('name');
//     localStorage.clear();
//     await navigate('/login')
//     notifySuccess({ Message: `Logged Out` })
// }


// const RegisterUser = async (data, Docs) => {
//     const docBody = {
//         docs: Docs,
//         userEmail: data.email
//     }
//     const emailBody = {
//         email: data.email
//     }
//     try {
//         const res = await postRequestWithFetch(`user/createuser/${data.role}/${data.position}/${data.department}`, data)
//         if (res.status === true) {
//             notifySuccess({ Message: res.message })
//             await postRequestWithFetch(`docs/askdoc`, docBody);
//             await postRequestWithFetch(`user/createUserEmail`, emailBody);
//             return await res.status;
//         } else {
//             res && notifyError({ Message: res.error })
//         }
//     } catch (error) {
//         notifyError({ Message: error })
//     }
// }

// const myProfile = async () => {
//     const res = await getRequestWithFetch(`user/curuser`);
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         return null
//     }
// }

// const getUserList = async () => {
//     try {
//         const res = await getRequestWithFetch('user/all');
//         if (res.status === true) {
//             return await res.data;
//         }
//     } catch (err) {
//         notifyError({ Message: `${err}` });
//     }
// }
// const getOnlyUserList = async () => {
//     try {
//         const res = await getRequestWithFetch('user/onlyuser');
//         if (res.status === true) {
//             return await res.data;
//         }
//     } catch (err) {
//         notifyError({ Message: `${err}` });
//     }
// }

// const updateUserStatus = async (data) => {
//     try {
//         const res = await putRequestWithFetch(`user/updatestatus/${data.userID}/${data.status}`)
//         if (res.status === true) {
//             notifySuccess({ Message: res.message })
//             return res.status;
//         }
//     } catch (err) {
//         notifyError({ Message: `${err}` });
//     }
// }

// const getAllLeaves = async (status, body) => {
//     try {

//         const res = await postRequestWithFetch(`leave/get/all/${status}`, body);
//         if (res && res.status === true) {
//             return res.data;
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }
// }

// const updateProfile = async (data) => {
//     try {
//         const res = await putRequestWithFetch('user/update', data);
//         if (res.status === true) {
//             notifySuccess({ Message: `profile Updated Successfully` })
//         } else {
//             notifyError({ Message: res.error })
//         }
//     } catch (err) {
//         notifyError({ Message: err })
//     }
// }

// const updateProfileById = async (data) => {
//     try {
//         const res = await putRequestWithFetch('user/update', data);
//         if (res.status === true) {
//             notifySuccess({ Message: `profile Updated Successfully` })
//         } else {
//             notifyError({ Message: res.error })
//         }
//     } catch (err) {
//         notifyError({ Message: err })
//     }
// }

const sendForgotPasswordRequest = async (email) => {
    if (email) {
        const body = {
            email: email,
        }
        const res = await postRequestWithFetch('user/forgotpass', body);
        if (res && res.status === true) {
            notifySuccess({ Message: res.message });
        } else {
            res && notifyError({ Message: res.error });
        }
    } else {
        notifyError({ Message: `Email must be required` });
    }
}
const forgotPassword = async (body) => {
    const res = await putRequestWithFetch('user/forgotpassword', body);
    if (res && res.status === true) {
        notifySuccess({ Message: res.message });
    } else {
        res && notifyError({ Message: res.error });
    }
}

// const changepassword = async (passwords) => {
//     const res = await putRequestWithFetch('user/changepass', passwords)
//     if (res.status === true) {
//         notifySuccess({ Message: res.message });
//     } else {
//         notifyError({ Message: res.error })
//     }
// }

// const updateLeaveStatus = async (data) => {

//     try {
//         const res = await putRequestWithFetch(`leave/updatestatus/${data.leaveid}/${data.status}`);
//         if (res && res.status === true) {
//             return res.data;
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }

// }

// const applyLeave = async (data) => {
//     try {
//         const res = await postRequestWithFetch(`leave/apply/${data.leaveid}`, data);
//         if (res && res.status === true) {
//             notifySuccess({ Message: res.message })
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }

// }

// const fetchRole = async () => {
//     try {
//         const res = await getRequestWithFetch(`role/get/all`);
//         if (res && res.status === true) {
//             return res.data;
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }
// }

// const fetchPosition = async () => {
//     try {
//         const res = await getRequestWithFetch(`pos/get/all`);
//         if (res && res.status === true) {
//             return res.data;
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }
// }

// const getUserById = async (userid) => {
//     if (userid !== "") {
//         const res = await getRequestWithFetch(`user/get/${userid}`);
//         if (res && res.status === true) {
//             return await res.data;
//         } else {
//             return null
//         }
//     }
// }

const getAllAttendance = async (body) => {
    try {
        const res = await postRequestWithFetch(`dd/all`, body);
        if (res && res.status === true) {
            notifySuccess({ Messasge: 'Record Found' })
            return res.data;
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
}

// const getTodayAttendance = async (today) => {
//     try {
//         const res = await getRequestWithFetch(`dd/all/today`);
//         if (res && res.status === true) {
//             return res.data;
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }
// }

// const updateAttendanceStatus = async (entryId) => {
//     try {
//         const res = await putRequestWithFetch(`dd/update/${entryId}`);
//         if (res && res.status === true) {
//             notifySuccess({ Message: res.message })

//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }
// }

// const handleAssignUser = async (data) => {
//     try {
//         const res = await postRequestWithFetch(`mt/create`, data);
//         if (res.status === true) {
//             notifySuccess({ Message: res.message })
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }

// }

// const handleAssignUserRemove = async (data) => {
//     try {
//         const res = await putRequestWithFetch(`mt/alter`, data);
//         if (res.status === true) {
//             notifySuccess({ Message: res.message })
//         } else {
//             notifyError({ Message: res.error });
//         }
//     } catch (err) {
//         notifyError({ Message: err });
//     }

// }

// const getDashboard = async () => {
//     const res = await getRequestWithFetch(`user/dashboard`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }
// const getRole = async () => {
//     const res = await getRequestWithFetch(`role/get/all`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }

// const addRole = async (body) => {
//     const res = await postRequestWithFetch(`role/add`, body)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const updateRole = async ({ roleId, roleName }) => {
//     const res = await putRequestWithFetch(`role/update/${roleId}/${roleName}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const deleteRole = async ({ roleId }) => {
//     const res = await deleteRequestWithFetch(`role/delete/${roleId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const getPosition = async () => {
//     const res = await getRequestWithFetch(`pos/get/all`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }

// const addPosition = async (body) => {
//     const res = await postRequestWithFetch(`pos/add`, body)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const updatePosition = async ({ posId, posName }) => {
//     const res = await putRequestWithFetch(`pos/update/${posId}/${posName}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const deletePosition = async ({ posId }) => {
//     const res = await deleteRequestWithFetch(`pos/delete/${posId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const getDepartment = async () => {
//     const res = await getRequestWithFetch(`dept/get/all`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }

// const addDepartment = async (body) => {
//     const res = await postRequestWithFetch(`dept/add`, body)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const updateDepartment = async ({ deptId, deptName }) => {
//     const res = await putRequestWithFetch(`dept/update/${deptId}/${deptName}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }


// const deleteDepartment = async ({ deptId }) => {
//     const res = await deleteRequestWithFetch(`dept/delete/${deptId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const getLeaveType = async () => {
//     const res = await getRequestWithFetch(`lt/get/all`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }

// const updateUserRole = async (roleId, userId) => {
//     const res = await putRequestWithFetch(`user/update/role/${roleId}/${userId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }
// const updateUserPosition = async (posId, userId) => {
//     console.log(posId, userId);
//     const res = await putRequestWithFetch(`user/update/pos/${posId}/${userId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }
// const updateUserDepartment = async (deptId, userId) => {
//     const res = await putRequestWithFetch(`user/update/dept/${deptId}/${userId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const assignedUsersOfManager = async (body) => {
//     const res = await postRequestWithFetch(`user/assigneduser`, body);
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const handleUploadImage = async (userId, body) => {
//     const formData = new FormData();
//     formData.append('image', body);
//     console.log(body);
//     const res = await postRequestWithFetchForFile(`images/upload/image/${userId}`, formData)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message });
//         return res.status
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

const addDocsAsk = async (body) => {
    const res = await postRequestWithFetch(`docs/ask`, body)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.message });
        notifyError({ Message: res.error });
    }
}

// const getAskDocs = async () => {
//     const res = await getRequestWithFetch(`docs/pending`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         return null
//     }
// }

// const handleUploadAskedFile = async (docId, body) => {
//     const formData = new FormData();
//     formData.append('file', body);
//     console.log(body);
//     const res = await postRequestWithFetchForFile(`images/upload/file/${docId}`, formData)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message });
//         return res.status
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

const getUploadedDocs = async (userId) => {
    const res = await getRequestWithFetch(`docs/uploaded/${userId}`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        notifyError({ Message: res.error });
    }
}

// const getGraphData = async () => {
//     const res = await getRequestWithFetch(`dd/graphdata`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }

// const AddLeaveType = async (body) => {
//     const res = await postRequestWithFetch(`lt/add`, body)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }


// const updateLeaveType = async ({ leaveTypeId, leaveTypeName }) => {
//     const res = await putRequestWithFetch(`lt/update/${leaveTypeId}/${leaveTypeName}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const AddDocuments = async (body) => {
//     const res = await postRequestWithFetch(`rdt/add`, body)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const updateDocuments = async ({ docTypeId, docTypeName }) => {
//     const res = await putRequestWithFetch(`rdt/update/${docTypeId}/${docTypeName}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return await res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const deleteDocumentsType = async ({ docTypeId }) => {
//     const res = await deleteRequestWithFetch(`rdt/delete/${docTypeId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

// const getDocumentsList = async () => {
//     const res = await getRequestWithFetch(`rdt/get/all`)
//     if (res && res.status === true) {
//         return await res.data;
//     } else {
//         res && notifyError({ Message: res.error });
//     }
// }

// const deleteLeaveType = async ({ leaveTypeId }) => {
//     const res = await deleteRequestWithFetch(`lt/delete/${leaveTypeId}`)
//     if (res && res.status === true) {
//         notifySuccess({ Message: res.message })
//         return res.status;
//     } else {
//         notifyError({ Message: res.error });
//     }
// }

const updateBucket = async ({ editedValue, userID }) => {
    const res = await putRequestWithFetch(`user/update/bucket/${editedValue}/${userID}`)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const AddMessages = async (body) => {
    const res = await postRequestWithFetch(`message/send`, body)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const getMessageList = async () => {
    const res = await getRequestWithFetch(`message/inbox`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}
const getSentMessageList = async () => {
    const res = await getRequestWithFetch(`message/sent`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

const deleteMessage = async () => {
    const res = await deleteRequestWithFetch();
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

// const enrollFinger = async (userID) => {
//     try {
//         // Sending a GET request to the Arduino server
//         const res = await getRequestWithFetch(`ecc/enroll/${userID}`);
//         console.log("response : ", res);
//         if (res && res.status === true) {
//             notifySuccess({ Message: res.message });
//         } else {
//             notifyError({ Message: res.message })
//         }
//     } catch (error) {
//         console.error('There was a problem with the fetch operation:', error);
//     }
// }



// const deleteFinger = async (fingerID, userID) => {
//     try {
//         // Sending a GET request to the Arduino server
//         const res = await getRequestWithFetch(`ecc/delete/${fingerID}/${userID}`);
//         if (res && res.status === true) {
//             notifySuccess({ Message: "Finger Deleted Successfully!" });
//         } else {
//             notifyError({ Message: 'Failed to Delete Finger!' })
//         }
//     } catch (error) {
//         console.error('There was a problem with the fetch operation:', error);
//         // setEnrollmentStatus('Enrollment failed. See console for details.');
//     }
// }

const MarkAttendance = async () => {
    try {
        // Sending a GET request to the Arduino server
        const response = await fetch(`http://192.168.1.11/identify`, { mode: 'no-cors' });
        if (response.status === true) {
            const data = await response.data; // Assuming the response is text
            console.log(data); // Update the state with the response
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

const handleTimeIn = async (data) => {
    try {
        const response = await postRequestWithFetch(`user/timeIN`, data);
        if (response && response.status === true) {
            notifySuccess({ Message: response.message })
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

const handleTimeOut = async (data) => {
    try {
        const response = await postRequestWithFetch(`user/timeOUT`, data);
        if (response && response.status === true) {
            notifySuccess({ Message: response.message })
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

const handleHalfDay = async (data) => {
    try {
        const response = await postRequestWithFetch(`user/halfDay`, data);
        if (response && response.status === true) {
            notifySuccess({ Message: response.message })
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

const handleGracePeriod = async (data) => {
    try {
        const response = await postRequestWithFetch(`user/markAbsent`, data);
        if (response && response.status === true) {
            notifySuccess({ Message: response.message })
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

const handleGetTimeIn = async () => {
    try {
        const response = await getRequestWithFetch(`user/getTI`);
        if (response && response.status === true) {
            return await response.message;
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

const handleGetTimeOut = async (data) => {
    try {
        const response = await getRequestWithFetch(`user/getTO`, data);
        if (response && response.status === true) {
            return response.message;
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

const handleGetHalfDay = async (data) => {
    try {
        const response = await getRequestWithFetch(`user/getHD`, data);
        if (response && response.status === true) {
            return response.message;
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

const handleGetGracePeriod = async (data) => {
    try {
        const response = await getRequestWithFetch(`user/getMA`, data);
        if (response && response.status === true) {
            return response.message;
        } else {
            notifyError({ Message: response.error })
        }
    } catch (err) {
        notifyError({ Message: err })
    }
}

export {
    // login,
    // handleLogOut,
    // getUserList,
    // RegisterUser,
    // getAllLeaves,
    // updateLeaveStatus,
    // fetchRole,
    // fetchPosition,
    // getRole,
    // addRole,
    // updateRole,
    // deleteRole,
    // getPosition,
    // addPosition,
    // updatePosition,
    // deletePosition,
    // getDepartment,
    // addDepartment,
    // updateDepartment,
    // deleteDepartment,
    // getLeaveType,
    // AddLeaveType,
    // deleteLeaveType,
    // updateLeaveType,
    // AddDocuments,
    // getDocumentsList,
    // updateDocuments,
    // deleteDocumentsType,
    // applyLeave,
    // updateUserStatus,
    // myProfile,
    // getUserById,
    // updateProfileById,
    // updateProfile,
    // updateUserRole,
    // updateUserPosition,
    // updateUserDepartment,
    // changepassword,
    // getTodayAttendance,
    // enrollFinger,
    // deleteFinger,
    // getDashboard,
    // getGraphData,
    // getAskDocs,
    // handleUploadAskedFile,
    // handleUploadImage,
    // getOnlyUserList,    //
    // assignedUsersOfManager,   //
    // handleAssignUser,
    // handleAssignUserRemove,
    // updateAttendanceStatus,
    sendForgotPasswordRequest,
    forgotPassword,
    getAllAttendance,
    addDocsAsk,
    getUploadedDocs,
    updateBucket,
    AddMessages,
    getMessageList,
    getSentMessageList,
    deleteMessage,
    MarkAttendance,
    handleTimeIn,
    handleTimeOut,
    handleHalfDay,
    handleGracePeriod,
    handleGetTimeIn,
    handleGetTimeOut,
    handleGetHalfDay,
    handleGetGracePeriod,
} 