import { notifyError, notifySuccess } from "../../../notify/Notify";
import { getRequestWithFetch, patchRequestWithFetch, patchRequestWithFetchForFile, postRequestWithFetch } from "../../FetchingMethod"

const login = async (data, navigate) => {
    try {
        const res = await postRequestWithFetch(`user/login`, data);
        if (res?.status === true) {
            localStorage.setItem("token", res?.token);
            localStorage.setItem("role", res?.role);
            localStorage.setItem("name", res?.userName);
            await navigate("/");
            window.location.reload();
        } else {
            notifyError({
                Message: res?.error || "Please check your login Id and Password",
            });
        }
    } catch (error) {
        notifyError({ Message: "Please check your login Id and Password" });
    }
};

const forgotPassword = async (data, navigate) => {
    try {
        const res = await postRequestWithFetch("user/forgot/password", data);
        if (res.status === true) {
            return notifySuccess({ Message: res.meessage });
        } else {
            notifyError({ Message: res.error });
        }
    } catch (error) {
        notifyError({ Message: `${error}` });
    }
};

const resetPassword = async (data, navigate) => {
    try {
        const res = await postRequestWithFetch("user/reset/password", data);
        console.log(res);

        if (res.success === true) {
            notifySuccess({ Message: res.message });
            return navigate("/");
        } else {
            notifyError({ Message: res.error });
        }
    } catch (error) {
        notifyError({ Message: `${error}` });
    }
};

const handleLogOut = async (navigate) => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.clear();
    await navigate("/login");
    notifySuccess({ Message: `Logged Out` });
};

const getUserList = async (data) => {
    try {
        const res = !data ? await getRequestWithFetch('user/list') : await postRequestWithFetch('user/list', data)
        if (res.status === true) {
            return await res.data;
        }
    } catch (err) {
        notifyError({ Message: `${err}` });
    }
};

const RegisterUser = async (data, docsName) => {
    const newData = {
        data,
        docsName,
    };

    try {
        const res = await postRequestWithFetch(
            `user/registertion/${data.role}`,
            newData
        );
        if (res.status === true) {
            notifySuccess({ Message: res.message });
            // await postRequestWithFetch(`docs/askdoc`, docBody);
            // await postRequestWithFetch(`user/createUserEmail`, emailBody);
            return await res.status;
        } else {
            res && notifyError({ Message: res.error });
        }
    } catch (error) {
        notifyError({ Message: error });
    }
};

const updateUserStatus = async (data) => {
    try {
        const res = await patchRequestWithFetch(
            `user/update/${data.userID}/${data.status}`
        );
        if (res.status === true) {
            notifySuccess({ Message: res.message });
            return res.status;
        }
    } catch (err) {
        notifyError({ Message: `${err}` });
    }
};

const userProfile = async (userId) => {
    const opts = userId ? "/" + userId : "";

    const res = await getRequestWithFetch(`user/myProfile${opts}`);
    if (res && res.status === true) {
        return await res.data;
    } else {
        return null;
    }
};

const updateUserProfile = async (data, userId) => {
    const opts = userId ? "/" + userId : "";
    try {
        const res = await patchRequestWithFetch(`user/update${opts}`, data);
        if (res.status === true) {
            notifySuccess({ Message: `profile Updated Successfully` });
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
};

const changepassword = async (data) => {
    const res = await patchRequestWithFetch("user/changePassword", data);
    if (res.status === true) {
        notifySuccess({ Message: res.message });
    } else {
        notifyError({ Message: res.error });
    }
};

const handleUploadImage = async (userId, body) => {
    const userID = userId ? `/${userId}` : ""
    const formData = new FormData();
    formData.append('file', body);
    const res = await patchRequestWithFetchForFile(`user/profile/image${userID}`, formData)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message });
        return res.status
    } else {
        notifyError({ Message: res.error });
    }
}

const handleAssignUser = async (data) => {

    try {
        const res = await postRequestWithFetch(`user/assign/${data.managerId}`, { userId: data.userId });
        if (res.status === true) {
            notifySuccess({ Message: res.message })
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }

}

const handleAssignUserRemove = async (data) => {
    try {
        const res = await patchRequestWithFetch(`user/assign/update/${data.managerId}`, { userId: data.userId });
        if (res.status === true) {
            notifySuccess({ Message: res.message })
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }

}

export {
    login,
    forgotPassword,
    resetPassword,
    handleLogOut,
    getUserList,
    RegisterUser,
    updateUserStatus,
    userProfile,
    updateUserProfile,
    changepassword,
    handleUploadImage,
    handleAssignUser,
    handleAssignUserRemove
}

