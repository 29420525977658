import { notifyError, notifySuccess } from "../../../notify/Notify";
import { deleteRequestWithFetch, getRequestWithFetch, patchRequestWithFetch, postRequestWithFetch } from "../../FetchingMethod";

const getLeaveType = async () => {
    const res = await getRequestWithFetch(`leave/list`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}


const AddLeaveType = async (body) => {
    const res = await postRequestWithFetch(`leave/add`, body)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}


const updateLeaveType = async ({ leaveTypeId, leaveTypeName, balance, carry_forward, paid_leave }) => {

    const res = await patchRequestWithFetch(`leave/update/${leaveTypeId}`, { leaveTypeName, balance, carry_forward, paid_leave })
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return res.status;
    } else {
        notifyError({ Message: res.error });
    }
}
const deleteLeaveType = async ({ leaveTypeId }) => {
    const res = await deleteRequestWithFetch(`leave/delete/${leaveTypeId}`)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const applyLeave = async (data) => {
    try {
        const res = await postRequestWithFetch(`leave/apply`, data);
        if (res && res.status === true) {
            notifySuccess({ Message: res.message })
            return res.status;
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
}

const getAllLeaves = async (status, body) => {
    try {

        const res = await postRequestWithFetch(`leave/applied/list/${status}`, body);
        if (res && res.status === true) {
            return res.data;
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
}

const approval = async (data) => {
    try {
        const res = await patchRequestWithFetch(`leave/approval/${data.leaveid}/${data.status}`);
        if (res && res.status === true) {
            return res.data;
        } else {
            notifyError({ Message: res.error });
        }
    } catch (err) {
        notifyError({ Message: err });
    }
}


export {
    getLeaveType,
    AddLeaveType,
    updateLeaveType,
    deleteLeaveType,
    applyLeave,
    getAllLeaves,
    approval
}