import { notifyError, notifySuccess } from "../../../notify/Notify";
import { patchRequestWithFetch } from "../../FetchingMethod";

const updateUserPosition = async (posId, userId) => {
    console.log(posId, userId);
    const res = await patchRequestWithFetch(`position/user/update/${posId}/${userId}`)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

export {
    updateUserPosition
}