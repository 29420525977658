// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --glass: rgba(255, 255, 255, .4);
  --dialog_glass: rgba(255, 255, 255, .6);
  --blurr: blur(30px);
  --pd: 1.4em;
  --gradient: linear-gradient(135deg, #164088, #0A1322);
  --textColor: rgba(21, 64, 138);
  --header: 4rem auto;
  --g_radius: 20px;
  --shadow: 15px 15px 60px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,uCAAuC;EACvC,mBAAmB;EACnB,WAAW;EACX,qDAAqD;EACrD,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,2CAA2C;AAC7C;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,SAAS;EACT,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb","sourcesContent":[":root {\n  --glass: rgba(255, 255, 255, .4);\n  --dialog_glass: rgba(255, 255, 255, .6);\n  --blurr: blur(30px);\n  --pd: 1.4em;\n  --gradient: linear-gradient(135deg, #164088, #0A1322);\n  --textColor: rgba(21, 64, 138);\n  --header: 4rem auto;\n  --g_radius: 20px;\n  --shadow: 15px 15px 60px rgba(0, 0, 0, 0.1);\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Poppins', sans-serif;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Poppins', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
