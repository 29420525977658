import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { getUserDocumentsList } from '../../component/service/apis/documents/documents.api';

const ShowFiles = ({ userId }) => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (userId) {
            const fetchData = async () => {
                const data = await getUserDocumentsList(userId, 'uploaded');
                setFiles(data);
            };
            fetchData();
        }
    }, [userId]);

    return (
        <div>
            {files ? files.map((file, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    <a
                        href={file.file_name}
                        download={file.file_name}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: 'none',
                            color: '#0178DB',
                            fontWeight: 'bold',
                            display: 'inline-block',
                            marginRight: '15px'
                        }}
                    >
                        {file.docName}
                    </a>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.open(file.file_name, '_blank')}
                    >
                        Open
                    </Button>
                </div>
            ))
                : <p>No Documents Found</p>
            }
        </div>

    );
}

export default ShowFiles;
