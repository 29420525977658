import React, { useCallback, useEffect, useState } from 'react'
import './myprofile.css'
import FullScreenDialog from '../../component/dialogBox/FullScreenDialog'
import { Button, ButtonGroup, Divider, TextField } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AvatarImg from '../../component/assets/image/avatar.jpg'
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/material';
import { Tab } from '@mui/material';
// import TakeDocs from '../files/TakeDocs';
import UplaodFiles from '../files/UploadFiles';
import ShowFiles from '../files/ShowFiles';
import DeleteIcon from '@mui/icons-material/Delete';
import { getRole } from '../../component/service/apis/role/Role.api';
import { getPosition } from '../../component/service/apis/position/Position.api';
import { getDepartment } from '../../component/service/apis/department/department.api';
import { handleUploadImage, updateUserProfile, userProfile } from '../../component/service/apis/user/User.api';
import { updateUserRole } from '../../component/service/apis/role/UserRole.api';
import { updateUserPosition } from '../../component/service/apis/user/UserPosition.api';
import { updateUserDepartment } from '../../component/service/apis/department/UserDepartment.api';
import { deleteFinger } from '../../component/service/apis/attendance/attendance.api';
import { getUserDocumentsList } from '../../component/service/apis/documents/documents.api';

const role = localStorage.getItem('role');

const MyProfile = (props) => {

    const [enableEdit, setEnableEdit] = useState(false)
    const [userdata, setUserData] = useState({
        name: '',
        employeeID: '',
        userID: '',
        email: '',
        role: '',
        position: '',
        department: '',
        age: '',
        image: '',
        bloodGroup: '',
        phoneNumber: '',
        gender: '',
        address: '',
        altPhoneNumber: '',
        relation: '',
        relationName: '',
        fingerPrintID: ''
    })
    const [roleOptions, setRoleOptions] = useState([]);
    const [positionOptions, setPositionOptions] = useState([]);
    const [deptOptions, setdeptOptions] = useState([]);
    const [value, setValue] = React.useState('1');
    const [image, setImage] = useState('');

    const previewFiles = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImage(reader.result);
        }
    }

    const [fileData, setFileData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUserDocumentsList('', 'not uploaded');
            setFileData(data);
        };
        if (props.open === true) {
            fetchData();
        }
    }, [props]);

    const handleImage = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        previewFiles(file);
        file && imageUpload(file)
    }

    const imageUpload = (file) => {
        handleUploadImage(props.userid, file)
    }

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const edit = localStorage.getItem('role') !== 'user' && enableEdit ? { readOnly: false } : { readOnly: true };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    // fetching data from backend 
    const handleFetchUser = useCallback(async () => {
        if (props.open) {
            const res = props.userid ? await userProfile(props.userid, 'userById') : await userProfile();
            res && setUserData(res[0]);
            if (enableEdit) {
                const roleRes = await getRole();
                const posRes = await getPosition();
                const deptRes = await getDepartment();
                roleRes && setRoleOptions(roleRes)
                posRes && setPositionOptions(posRes)
                deptRes && setdeptOptions(deptRes)
            }
        }
    }, [props, enableEdit]);

    useEffect(() => {
        handleFetchUser();
    }, [handleFetchUser])

    const handleEditUser = () => {
        const data = {
            name: userdata.name,
            address: userdata.address,
            contact: userdata.phoneNumber,
            alt_contact: userdata.altPhoneNumber,
            relation: userdata.relation,
            blood_group: userdata.bloodGroup,
            relation_name: userdata.relationName,
            image: userdata.image,
            employeeID: userdata.employeeID
        }
        console.table(data);
        props.userid ? updateUserProfile(data, props.userid) : updateUserProfile(data);

        handleFetchUser()
        setEnableEdit(false)
    }

    const handleCancelEdit = () => {
        setEnableEdit(false)
        handleFetchUser();
    }

    const handleUpdateUserRole = (e) => {
        updateUserRole(e.target.value, props.userid)
        handleFetchUser()
    }
    const handleUpdateUserPosition = (e) => {
        updateUserPosition(e.target.value, props.userid)
        handleFetchUser()
    }
    const handleUpdateUserDepartment = (e) => {
        updateUserDepartment(e.target.value, props.userid)
        handleFetchUser()
    }

    const profileDetails = <React.Fragment>
        <TextField size='small' className='TextField' value={userdata.name || ''} onChange={handleChange} InputProps={edit} type='text' name='name' id="name" label="Name" variant="outlined" />
        <TextField size='small' className='TextField' value={userdata.employeeID || ''} onChange={handleChange} InputProps={edit} type='text' name='employeeID' id="employeeID" label="Employee ID" variant="outlined" />
        <TextField size='small' className='TextField' value={userdata.email || ''} onChange={handleChange} InputProps={edit} type='email' name='email' id="email" label="Email" variant="outlined" />
        {enableEdit ?
            <FormControl className='TextField' size="small">
                <InputLabel>Role</InputLabel>
                <Select
                    name='role'
                    value={roleOptions.find(option => option.role_name === userdata.role)?.id || ''}
                    onChange={(e) => handleUpdateUserRole(e)}
                    label="Role"
                >
                    {roleOptions.map((option) => (
                        <MenuItem key={option.role_name} value={option.id}>{option.role_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            :
            <TextField
                className='TextField'
                size="small"
                value={userdata.role || ''}
                onChange={handleChange}
                InputProps={{ readOnly: true }}
                label="Role"
            />
        }

        {enableEdit ?
            <FormControl className='TextField' size="small">
                <InputLabel>Position</InputLabel>
                <Select
                    name='position'
                    value={positionOptions.find(option => option.position_name === userdata.position)?.id || ''}
                    onChange={handleUpdateUserPosition}
                    label="Position"
                >
                    {positionOptions.map((option) => (
                        <MenuItem key={option.position_name} value={option.id}>{option.position_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            :
            <TextField
                className='TextField'
                size="small"
                value={userdata.position || ''}
                onChange={handleChange}
                InputProps={{ readOnly: true }}
                label="Position"
            />
        }

        {enableEdit ?
            <FormControl className='TextField' size="small">
                <InputLabel>Department</InputLabel>
                <Select
                    name='department'
                    value={deptOptions.find(option => option.department_name === userdata.department)?.id || ''}
                    onChange={handleUpdateUserDepartment}
                    label="Department"
                >
                    {deptOptions.map((option) => (
                        <MenuItem key={option.department_name} value={option.id}>{option.department_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            :
            <TextField
                className='TextField'
                size="small"
                value={userdata.department || ''}
                onChange={handleChange}
                InputProps={{ readOnly: true }}
                label="Department"
            />
        }

        <TextField size="small" className='TextField' value={userdata.age || ''} onChange={handleChange} InputProps={edit} type='number' name='age' id="age" label="Age" variant="outlined" />
        <TextField size="small" className='TextField' value={userdata.bloodGroup || ''} onChange={handleChange} InputProps={edit} type='text' name='bloodGroup' id="bloodGroup" label="Blood Group" variant="outlined" />
        <TextField size="small" className='TextField' value={userdata.phoneNumber || ''} onChange={handleChange} InputProps={edit} type='number' name='phoneNumber' id="phoneNumber" label="Contact" variant="outlined" />
        <TextField size="small" className='TextField' value={userdata.gender || ''} onChange={handleChange} InputProps={edit} type='text' name='gender' id="gender" label="Gender" variant="outlined" />
        <TextField size="small" className='TextField' value={userdata.address || ''} onChange={handleChange} InputProps={edit} type='text' name='address' id="address" label="Address" variant="outlined" />
        <TextField size="small" className='TextField' value={userdata.altPhoneNumber || ''} onChange={handleChange} InputProps={edit} type='number' name='altPhoneNumber' id="altPhoneNumber" label="Alt Contact" variant="outlined" />
        <TextField size="small" className='TextField' value={userdata.relation || ''} onChange={handleChange} InputProps={edit} type='text' name='relation' id="relation" label="Relation" variant="outlined" />
        <TextField size="small" className='TextField' value={userdata.relationName || ''} onChange={handleChange} InputProps={edit} type='text' name='relationName' id="relationName" label="Relation Name" variant="outlined" />
    </React.Fragment>

    const Content = <React.Fragment>
        <div className='profileContainer'>
            <div className='profileContainer1'>
                <div className='backButton'>
                    <Button sx={{ color: 'white', '&:hover': { background: 'none', color: 'white' } }} variant='text' startIcon={<ArrowBackIcon />} onClick={props.onClose}>Go Back</Button>
                </div>
                <div className='profileContainer2'>
                    <div className='profileImg'>

                        <div className='img' onClick={() => document.getElementById('imageUpload').click()}>
                            <img height={'100%'} width={'100%'} style={{ borderRadius: '50%', }} src={image || userdata.image || AvatarImg} alt='profileImage' />
                            <input type="file" id="imageUpload" accept=".jpg, .jpeg" style={{ display: 'none' }} onChange={(e) => handleImage(e)} />
                        </div>

                        <h2>{userdata.name || ''}</h2>
                        <p>{userdata.position || ''}</p>
                        <Divider style={{ width: '50%', margin: '.5em' }} />
                        <span>Address</span>
                        <p>{userdata.address || ''}</p>
                        <br />
                        {
                            (role === 'admin' || role === 'super admin') && (
                                enableEdit === false ?
                                    <React.Fragment>
                                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                                            <Button style={{ color: 'rgba(0,0,0,.7)', borderColor: 'rgba(0,0,0,.7)', width: '50%' }} onClick={() => setEnableEdit(true)} variant='outlined'>Edit</Button>
                                            <Button
                                                style={{ color: 'rgba(252, 3, 3,.7)', borderColor: 'rgba(252, 3, 3,.7)', width: '50%', padding: '0 3em' }}
                                                onClick={() => deleteFinger(userdata.userID)}
                                                variant='outlined'
                                                startIcon={<DeleteIcon />} // Place at the start
                                            >
                                                Biometric
                                            </Button>
                                        </ButtonGroup>
                                    </React.Fragment>
                                    :
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <Button color='success' onClick={() => handleEditUser()}>Save</Button>
                                        <Button color='error' onClick={() => handleCancelEdit()}>Cancel</Button>
                                    </ButtonGroup>
                            )
                        }
                    </div>
                    <div className='tabProfileDetails'>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeTab} aria-label="lab API tabs example" centered>
                                    <Tab label="User Details" value="1" />
                                    {(role === 'super admin' || role === 'admin') && <Tab label="Docs" value="2" />}
                                    {fileData && <Tab label="Uplaod Docs" value="3" />}
                                    {//((role === 'super admin' || role === 'admin') && props.userid) && <Tab label="Take Files" value="4" />
                                    }
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <div className='profileDetails'>
                                    {profileDetails}
                                </div>
                            </TabPanel>

                            <TabPanel value="2">
                                {(role === 'super admin' || role === 'admin') && <ShowFiles userId={props.userid} />}
                            </TabPanel>
                            {fileData &&
                                <TabPanel value="3">
                                    <UplaodFiles fileData={fileData} />
                                </TabPanel>
                            }
                            {
                                //   (role === 'super admin' || role === 'admin') && <TabPanel value="4">
                                //       <TakeDocs userId={props.userid} />
                                //   </TabPanel>
                            }

                        </TabContext>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment >

    return (
        <React.Fragment>
            <FullScreenDialog
                {...props}
                body={Content}
            />
        </React.Fragment>
    )
}

export default MyProfile
