import React, { useCallback, useEffect, useState } from 'react'
import './user.css'
import FullScreenDialog from '../../component/dialogBox/FullScreenDialog'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AvatarImg from '../../component/assets/image/avatar.jpg'


import { Button } from '@mui/material'
import { Divider } from '@mui/material';
import OnlyUserList from './OnlyUserList';
import { userProfile } from '../../component/service/apis/user/User.api';


const AssignUser = (props) => {

    const [userdata, setUserData] = useState({
        name: '',
        userID: '',
        email: '',
        role: '',
        position: '',
        age: '',
        bloodGroup: '',
        phoneNumber: '',
        gender: '',
        address: '',
        altPhoneNumber: '',
        relation: '',
        image: ''
    })

    const handleUserManager = useCallback(async () => {
        const Manager = await userProfile(props.userid)
        Manager && setUserData(Manager[0]);
    }, [props]);


    useEffect(() => {
        handleUserManager();
    }, [handleUserManager])


    const Content = <React.Fragment>
        <div className='AUContainer'>
            <div className='AUContainer1'>
                <div className='backButton'>
                    <Button sx={{ color: 'white', '&:hover': { background: 'none', color: 'white' } }} variant='text' startIcon={<ArrowBackIcon />} onClick={props.onClose}>Go Back</Button>
                </div>
                <div className='AUContainer2'>
                    <div className='profileImg'>
                        <div className='img'>
                            <img height={'100%'} style={{ borderRadius: '50%' }} src={userdata.image || AvatarImg} alt='profileImage' />
                        </div>
                        <h2>{userdata.name || ''}</h2>
                        <p>{userdata.role || ''}</p>
                        <p>({userdata.position})</p>
                        <Divider style={{ width: '50%', margin: '.5em' }} />
                        <span>Address</span>
                        <p>{userdata.address || ''}</p>
                        <br />

                    </div>
                    <div className='userList'>
                        <OnlyUserList managerId={props.userid} />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>

    return (
        <React.Fragment>
            <FullScreenDialog
                {...props}
                body={Content}
            />
        </React.Fragment>
    )
}

export default AssignUser
