import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function BasicTimePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        sx={{
          width: '100%',
        }}
        components={['TimePicker']}>
        <TimePicker
          sx={{
            display: 'flex',
            width: '100%',
          }}
          label={props.label}
          onChange={props.onChange}
          value={props.selectedTime}
          onAccept={props.onTimeSelected}
          timeSteps={{minutes:1}}
          />
      </DemoContainer>
    </LocalizationProvider>
  );
}