import React from 'react';
import './ResponsiveCards.css';


const ResponsiveCards = (props) => {
    return (
        <div style={{ backgroundColor: props.bgcolor }} className="card">
            <div className='cardContent'>
                <div className='Icondiv'>
                    {props.Icon}
                </div>
                <div className='content'>
                    <p style={{ fontSize: '28px' }}>{props.Count}</p>
                    <p style={{ fontSize: '14px' }}>{props.title}</p>
                </div>
            </div>
        </div>

    )
}

export default ResponsiveCards