import React, { useEffect, useState } from 'react'
import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { notifyWarning } from '../../component/notify/Notify';
import { applyLeave, getLeaveType } from '../../component/service/apis/leave/Leave.api';

const ApplyLeave = (props) => {

    const [data, setdata] = useState({
        Leavetype: '',
        leaveFrom: '',
        leaveTo: '',
        leaveDescription: ''
    });

    const [leavetype, setLeaveType] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setdata(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleDateChange = (newValue, name) => {
        setdata(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };

    useEffect(() => {
        const fetchLeaves = async () => {
            const res1 = await getLeaveType()
            const res = res1.filter(row => row.leave_name !== 'LWP');
            res && setLeaveType(res)
        }
        props.open === true && fetchLeaves()
    }, [props.open])


    const handleApplyLeave = async () => {
        const leavedata = {
            leaveId: data.Leavetype,
            leaveFrom: data.leaveFrom,
            leaveTo: data.leaveTo,
            leaveDescription: data.leaveDescription
        }
        // leavedata.leaveId ? applyLeave(leavedata) : notifyWarning({ Message: 'Please fill all fields' })
        if (leavedata.leaveId) {
            const res = await applyLeave(leavedata);
            props.onHide();
            res && props.handleLeaveList();

        } else {
            notifyWarning({ Message: 'Please fill all fields' })
        }
    }

    const Body = <React.Fragment>
        <Container className='popup' sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
            }}>
                <p>
                    Apply For Leaves
                </p>
            </Box>
            <Box sx={{ width: '100%' }}>
                <FormControl sx={{ m: 1, display: 'flex', gap: '.5em' }}>
                    <InputLabel id="demo-select-small-label">Type of Leaves</InputLabel>
                    <Select
                        // sx={{display:'flex'}}
                        name='Leavetype'
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={data.Leavetype}
                        onChange={handleChange}
                        autoWidth
                        label="Type of Leaves"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            leavetype.map((leave) => {
                                return <MenuItem key={leave.leave_name} value={leave.id}>{leave.leave_name}</MenuItem>
                            })
                        }


                    </Select>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker name='leaveFrom' onChange={(newValue) => handleDateChange(newValue, 'leaveFrom')} label="Start" />
                        <MobileDatePicker name='leaveTo' onChange={(newValue) => handleDateChange(newValue, 'leaveTo')} label="End" />
                    </LocalizationProvider>

                    <TextareaAutosize name='leaveDescription' onChange={handleChange} style={{ padding: '0px 5px', backgroundColor: 'inherit' }} placeholder="Reason" minRows={4} />
                    <Button style={{ backgroundColor: 'rgba(21, 64, 138,.8)' }} size='large' onClick={() => handleApplyLeave()} variant="contained">Apply</Button>
                    <Button style={{ opacity: '.8' }} color='error' size='large' variant="outlined" onClick={props.onHide}>Cancel</Button>
                </FormControl>
            </Box>
        </Container>
    </React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default ApplyLeave