const { notifyError } = require("../../../notify/Notify");
const { getRequestWithFetch } = require("../../FetchingMethod");

const getDashboardCards = async () => {
    const res = await getRequestWithFetch(`user/dashboard/cards`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

const getGraphData = async () => {
    const res = await getRequestWithFetch(`attendance/graph`);
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

export {
    getDashboardCards,
    getGraphData,
}