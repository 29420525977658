import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./views/login/Login";
import PublicRoutes from "./component/layout/PublicRoutes";
import PrivateRoutes from "./component/layout/PrivateRoutes";
import Layout from "./component/layout/Layout";
import ForgetPassword from "./views/forget/ForgetPassword";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="*" element={<Layout />} exact />
          </Route>
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot/:token/*" element={<ForgetPassword />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
 
export default App;
