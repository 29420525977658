import React, { useCallback, useEffect, useMemo, useState } from "react";
import MUIDataTable from "mui-datatables";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import AvatarImg from "../../component/assets/image/avatar.jpg";
import { Avatar, styled, TextField, Typography } from "@mui/material";
import "./attendance.css";
import StatusSelect from "./StatusSelect";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Chip } from "@mui/material";
import { notifySuccess } from "../../component/notify/Notify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { FcDataConfiguration } from "react-icons/fc";
// import { IconButton } from "@mui/material";
import AttendanceConfig from "../TimeInOutComponent/AttendanceConfig";
import { getTodayAttendance } from "../../component/service/apis/attendance/attendance.api";
// import MyProfile from "../myprofile/MyProfile";
// import Dialog from "@mui/material/Dialog";
// import Slide from "@mui/material/Slide";
import FullScreenDialog from "../../component/dialogBox/FullScreenDialog";
// import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";

const filterMenu = [
  { name: "Today Attendance" },
  { name: "This Month" },
  { name: "Custom Date" },
];

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Attendance = () => {
  const [attendanceStatus, setAttendanceStatus] = useState("Today Attendance");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [data, setData] = useState([]);
  const [openConfig, setOpenConfig] = useState(false);
  const [open, setOpen] = React.useState(false);
  // const [userid, setUserID] = useState({});

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleGetAllAttendance = useCallback(async () => {
    let res;
    switch (attendanceStatus) {
      case "This Month":
        const curMonth = {
          month: 1,
        };
        res = await getTodayAttendance(curMonth);
        break;
      case "Custom Date":
        if (dateRange.from && dateRange.to) {
          const data = {
            from: dateRange.from,
            to: dateRange.to,
          };
          notifySuccess({ Message: data });
          res = await getTodayAttendance(data);
        }
        break;
      default:
        res = await getTodayAttendance();
        break;
    }
    if (res) {
      const res1 = res.map((row) => ({
        ...row,
        totalLeave: row?.totalLeave != null ? row?.totalLeave : 0,
        totalAttendence:
          row?.totalAttendence != null ? row?.totalAttendence : 0,
      }));
      setData(res1);
    }
  }, [attendanceStatus, dateRange]);

  useEffect(() => {
    handleGetAllAttendance();
  }, [handleGetAllAttendance]);

  // const handleClickUser = (id) => {
  //   setOpen(true);
  //   setUserID(id);
  // };

  const baseColumns = [
    {
      name: "S.No",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return <div>{dataIndex + 1}</div>;
        },
      },
    },
    {
      name: "name",
      label: "Employee",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const leaves = tableMeta.rowData;
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            // onClick={() => handleClickUser(leaves)}
            >
              <Avatar
                alt={leaves[1]}
                src={leaves[11] || AvatarImg}
                style={{ marginRight: "20px" }}
              />
              <div>
                <Typography variant="body1">{leaves[1]}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {leaves[2]}
                </Typography>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "position",
      label: "Position",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lastEventTime",
      label: "Punch Date/Time",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const formattedDate = moment(value).format('MMM D, YYYY h:mm A');
          return (
            <React.Fragment>
              <div>{formattedDate}</div>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "lastEventType",
      label: "Punch In/Out",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          let event
          if (value === 'punch_in') {
            event = "Punch In"
          }else if (value === 'punch_out'){
            event = "Punch Out"
          }
          return (
            <React.Fragment>
              <div>{event}</div>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          return (
            <React.Fragment>
              <StatusSelect status={value} userId={tableMeta.tableData[rowIndex].attendanceId} />
            </React.Fragment>
          );
        },
      },
    },
    // {
    //   name: "today_date",
    //   label: "Today Date",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    // {
    //     name: "totalLeave",
    //     label: "Total Leave",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     },
    // },
    // {
    //     name: "totalAttendence",
    //     label: "Total Attendence",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     },
    // },
    // {
    //     name: "systemType",
    //     label: "System Type",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     },
    // },

    {
      name: "image",
      label: "User Image",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "userID",
      label: "userId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  // Dynamically check for non-null attendanceStatus
  const includeAttendanceStatusColumn = data.some(
    (row) => row.attencenceStatus != null
  );
  // const totalLeave = data.some(row => row.totalLeave != null);
  // const totalAttendence = data.some(row => row.totalAttendence != null);

  // Generate the full columns array
  const columns = useMemo(() => {
    let dynamicColumns = [...baseColumns]; // Start with base columns

    if (includeAttendanceStatusColumn) {
      dynamicColumns.push({
        name: "attencenceStatus",
        label: "Status",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const employee = tableMeta.rowData;
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <StatusSelect status={value} entryId={employee[0]} />
              </div>
            );
          },
        },
      });
    }

    return dynamicColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeAttendanceStatusColumn]); // Recalculate when data changes

  const handleChangeStatus = (e) => {
    setAttendanceStatus(e.target.value);
    if (e.target.value !== "Custom Date") {
      setDateRange({ from: null, to: null });
    }
  };

  const handleDateChange = (date, type) => {
    setDateRange((prev) => ({ ...prev, [type]: date }));
  };

  const options = {
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 30, 50],
    elevation: 0,
    fixedHeader: true,
    responsive: "standard",
    selectableRows: "none",
    customToolbar: () => <CustomToolbar />,
  };
  // const options2 = {
  //   rowsPerPage: 5,
  //   rowsPerPageOptions: [5, 10, 20],
  //   elevation: 0,
  //   fixedHeader: true,
  //   responsive: "standard",
  //   selectableRows: "none",
  //   customToolbar: () => <CustomToolbar />,
  // };

  const Content = (
    <React.Fragment>
      <div className="profileContainer">
        <div className="profileContainer1">
          <div className="container-md backButton d-flex flex-row align-items-center justify-content-between pb-3 text-white">
            <div className="d-flex align-items-center gap-3">
              <ArrowBackIcon
                onClick={() => setOpen(false)}
                sx={{ cursor: "pointer" }}
              />

              <div className="fw-medium fs-5">Back</div>
            </div>
            {/* <div>
              <TextField
                type="date"
                name="From"
                label="From"
                size="small"
                focused
              />
              <TextField
                sx={{ marginLeft: "20px" }}
                type="date"
                name="To"
                label="To"
                size="small"
                focused
              />
              
            </div> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <CustomInput
                  label="From"
                  value={dateRange.from}
                  onChange={(date) => handleDateChange(date, "from")}
                  renderInput={(params) => <TextField {...params} />}
                />
                <CustomInput
                  label="To"
                  value={dateRange.to}
                  onChange={(date) => handleDateChange(date, "to")}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          </div>
          {
            // <div
            //   className="container-md p-0 gap-0"
            //   style={{ height: "600px", overflowY: "scroll" }}
            // >
            //   <StyledMUIDataTable
            //     // sx={{ width: "100vw" }}
            //     title={`${userid[1]}'s Attendance`}
            //     data={data}
            //     columns={columns}
            //     options={options2}
            //   />
            // </div>
          }
        </div>
      </div>
    </React.Fragment>
  );

  const CustomToolbar = () => {
    return (
      <React.Fragment>
        <FormControl>
          <Select
            labelId="status-select-label"
            id="status-select"
            label="Status"
            variant="standard"
            value={attendanceStatus}
            onChange={(e) => handleChangeStatus(e)}
            renderValue={(selected) => (
              <Chip
                label={selected}
                sx={{
                  backgroundColor: "rgba(21, 64, 138, .5)",
                  color: "whitesmoke",
                }}
              />
            )}
          >
            {filterMenu.map(({ name }) => {
              return (
                <MenuItem key={name} value={name}>
                  <Chip
                    label={name}
                    sx={{
                      backgroundColor: "rgba(21, 64, 138, .5)",
                      color: "whitesmoke",
                    }}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {/* <IconButton onClick={() => setOpenConfig(true)}>
          <FcDataConfiguration />
        </IconButton> */}
      </React.Fragment>
    );
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p>Attendance Log</p>
      {attendanceStatus === "Custom Date" && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <CustomInput
              label="From"
              value={dateRange.from}
              onChange={(date) => handleDateChange(date, "from")}
              renderInput={(params) => <TextField {...params} />}
            />
            <CustomInput
              label="To"
              value={dateRange.to}
              onChange={(date) => handleDateChange(date, "to")}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {/* <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        hellooooo
        <button onClick={handleClose}>back</button>
        <p>have to fetch details for{userid}</p>
      </Dialog> */}
      <FullScreenDialog
        open={open}
        onClose={() => setOpen(false)}
        body={Content}
      />
      <StyledMUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
      <AttendanceConfig
        open={openConfig}
        onHide={() => setOpenConfig(false)}
        setOpen={setOpenConfig}
      />
    </React.Fragment>
  );
};

export default Attendance;

const StyledMUIDataTable = styled(MUIDataTable)({
  "&.MuiPaper-root": {
    backgroundColor: "var(--glass) !important",
    backdropFilter: "var(--blurr) !important",
  },
  "&.MuiTableCell-head": {
    backgroundColor: "var(--glass) !important",
    backdropFilter: "var(--blurr) !important",
  },
});

const CustomInput = styled(DatePicker)({
  "&>.MuiOutlinedInput-root": {
    display: "flex",
    alignItems: "center",
    width: "10em !important",
    height: "3em !important",
  },
});