
import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { styled, Tooltip, IconButton, TextField, FormControlLabel, Switch } from '@mui/material';
import './leaves.css';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import AddLeavesType from './AddLeavesType';
import { deleteLeaveType, getLeaveType, updateLeaveType } from '../../component/service/apis/leave/Leave.api';

const LeaveType = () => {
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [leaveBalance, setLeaveBalance] = useState('');
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [indexLeaveType, setIndexLeaveType] = useState('');
    const [carryForward, setCarryForward] = useState(false);
    const [paidLeave, setPaidLeave] = useState(false);


    const body = (
        <div className='popup'>
            <Typography variant="h5" gutterBottom>
                Are you sure to delete Leave Type?
            </Typography>
            <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                <Button sx={{ width: '100%', opacity: '.8' }} onClick={() => handleDelete()} color='success' variant='contained'>Yes</Button>
                <Button sx={{ width: '100%' }} onClick={() => setOpenPopup(false)} color='error'>Cancel</Button>
            </ButtonGroup>
        </div>
    );

    const handleLeaveTypeList = async () => {
        const res = await getLeaveType();
        if (res) {
            setLeaveTypeList(res);
        }
    }

    const handleChangeDelete = (index) => {
        setIndexLeaveType(index);
        setOpenPopup(true)
    }

    useEffect(() => {
        handleLeaveTypeList();
    }, [])

    const handleEdit = (index, leaveType, balance, carry_forward, paid_leave) => {
        setEditableRow(index);
        setEditValue(leaveType);
        setLeaveBalance(balance);
        setCarryForward(carry_forward);
        setPaidLeave(paid_leave);
    };

    const handleSave = (index) => {
        const leaveTypeId = leaveTypeList[index].id;
        const data = {
            leaveTypeId: leaveTypeId,
            leaveTypeName: editValue,
            balance: leaveBalance,
            carry_forward: carryForward,
            paid_leave: paidLeave,
        }
        updateLeaveType(data).then((res) => {
            res === true && handleLeaveTypeList()
        })
        setEditableRow(null);
    };

    const handleDelete = () => {
        setOpenPopup(false)
        const leaveTypeId = leaveTypeList[indexLeaveType].id;
        const data = {
            leaveTypeId: leaveTypeId,
        }
        deleteLeaveType(data).then((res) => {
            res === true && handleLeaveTypeList()
        })
        handleLeaveTypeList()
    }

    const handleCancel = () => {
        setEditableRow(null);
    };

    const columns = [
        {
            name: "S.No",
            label: "S.No",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => <div>{dataIndex + 1}</div>,
            },
        },
        {
            name: "leave_name",
            label: "leave type",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            size="small"
                        />;
                    }
                    return <div>{leaveTypeList[dataIndex].leave_name}</div>;
                },
            },
        },
        {
            name: "balance",
            label: "Leave Balance",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            value={leaveBalance}
                            onChange={(e) => setLeaveBalance(e.target.value)}
                            size="small"
                            type='number'
                        />;
                    }
                    return <div>{leaveTypeList[dataIndex].balance}</div>;
                },
            },
        },
        {
            name: "carry_forward",
            label: "Carry Forward",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={carryForward}
                                        onChange={(e) => setCarryForward(e.target.checked)}
                                        size="small"
                                    />
                                }
                            // label="Carry Forward"
                            />
                        );
                    }
                    return <div>{leaveTypeList[dataIndex].carry_forward ? 'Yes' : 'No'}</div>;
                },
            },
        },
        {
            name: "paid_leave",
            label: "Paid Leave",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={paidLeave}
                                        onChange={(e) => setPaidLeave(e.target.checked)}
                                        size="small"
                                    />
                                }
                            // label="Paid Leave"
                            />
                        );
                    }
                    return <div>{leaveTypeList[dataIndex].paid_leave ? 'Yes' : 'No'}</div>;
                },
            },
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return (
                            <div>
                                <IconButton color='success' onClick={() => handleSave(dataIndex)}>
                                    <DoneIcon fontSize='small' />
                                </IconButton>
                                <IconButton color='error' onClick={handleCancel}>
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            </div>
                        );
                    }
                    return (
                        <div>
                            <IconButton onClick={() => handleEdit(dataIndex, leaveTypeList[dataIndex].leave_name, leaveTypeList[dataIndex].balance, leaveTypeList[dataIndex].carry_forward, leaveTypeList[dataIndex].paid_leave)}>
                                <EditIcon fontSize='small' />
                            </IconButton>
                            <IconButton color='error' onClick={() => handleChangeDelete(dataIndex)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </div>
                    );
                },
            },
        },
    ];

    const CustomToolbar = () => (
        <Tooltip title='Add Role'>
            <IconButton onClick={() => setOpen(true)}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => <CustomToolbar />,
    };



    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={"Leave Type List"}
                data={leaveTypeList}
                columns={columns}
                options={options}
            />
            <AddLeavesType
                open={open}
                onHide={() => setOpen(false)}
                setOpen={setOpen}
                handleLeaveTypeList={handleLeaveTypeList}
            />
            <ResponsiveDialog
                open={openPopup}
                onHide={() => setOpenPopup(false)}
                Content={body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    );
}

export default LeaveType;

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});
