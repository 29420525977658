import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { IconButton, Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { postRequestWithFetch } from "../../component/service/FetchingMethod";
import { Avatar } from "@mui/material";
import AssignShifts from "./AssignShifts";
import ShiftAssignToUser from "./ShiftAssignToUser";

const UserShifts = () => {
  const [shiftData, setShiftData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [userId, setUserId] = useState(null);
  // const [currentShift, setCurrentShift] = useState(null);
  // const [newShift, setNewShift] = useState({ time: '', name: '' });
  const [currentStartDate, setCurrentStartDate] = useState(
    dayjs().startOf("day")
  ); // Start of current week
  const [currentEndDate, setCurrentEndDate] = useState(dayjs().add(6, "day")); // End of the current week (7 days)

  // Fetch shifts from the API based on the current date range
  const fetchShifts = async (startDate, endDate) => {
    try {
      const body = {
        from: startDate.format("YYYY-MM-DD"), // Send the start date in the required format
        to: endDate.format("YYYY-MM-DD"), // Send the end date in the required format
      };
      const response = await postRequestWithFetch(
        "/shift/assigned/users/list",
        body
      );
      const apiData = response.data;

      // Transform API data into the format expected by the MUI-Datatable
      const formattedShiftData = apiData.map((item) => {
        const shiftEntry = { employeeName: item.user, employeeId: item.userId };

        item.shifts.forEach((shiftItem) => {
          const formattedDate = dayjs(shiftItem.date).format("DD MMM");
          if (shiftItem.shift) {
            shiftEntry[formattedDate] = {
              time: `${shiftItem.shift.timeIn.slice(
                0,
                5
              )} - ${shiftItem.shift.timeOut.slice(0, 5)}`,
              name: shiftItem.shift.shiftName,
            };
          } else {
            shiftEntry[formattedDate] = null; // No shift assigned
          }
        });

        return shiftEntry;
      });

      setShiftData(formattedShiftData);
    } catch (error) {
      console.error("Error fetching shift data:", error);
    }
  };

  // Call the fetchShifts function when the component mounts and whenever the date range changes
  useEffect(() => {
    fetchShifts(currentStartDate, currentEndDate);
  }, [currentStartDate, currentEndDate]);

  // Calculate the next 7 days based on currentStartDate
  const getNext7Days = (startDate) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(startDate.add(i, "day"));
    }
    return days;
  };

  const next7Days = getNext7Days(currentStartDate);

  // Function to generate random light colors
  const getRandomLightColor = () => {
    const r = Math.floor(Math.random() * 106) + 150; // 150-255 range for light colors
    const g = Math.floor(Math.random() * 106) + 150;
    const b = Math.floor(Math.random() * 106) + 150;
    return { color: `rgb(${r}, ${g}, ${b})`, r, g, b }; // Return color and RGB values
  };

  // Function to generate a font color based on background color
  const getFontColorForBackground = (r, g, b) => {
    const darken = (c) => Math.max(0, c - 70); // Darken the color a bit
    const lighten = (c) => Math.min(255, c + 70); // Lighten the color a bit

    const isLight = 0.299 * r + 0.587 * g + 0.114 * b > 186; // Determine if the color is light

    // If the background is light, darken the font color, otherwise lighten it
    const fontR = isLight ? darken(r) : lighten(r);
    const fontG = isLight ? darken(g) : lighten(g);
    const fontB = isLight ? darken(b) : lighten(b);

    return `rgb(${fontR}, ${fontG}, ${fontB})`;
  };

  // Create dynamic columns based on next 7 days
  const createColumns = () => {
    const columns = [
      {
        name: "employeeName",
        label: "Employee Name",
        options: {
          customBodyRender: (value) => {
            const { color, r, g, b } = getRandomLightColor(); // Get random light color and its RGB values
            const textColor = getFontColorForBackground(r, g, b); // Determine font color from the background

            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* Create Avatar with a random light background color and harmonious font color */}
                <Avatar
                  style={{
                    marginRight: "10px",
                    backgroundColor: color,
                    color: textColor,
                  }}
                >
                  {value.charAt(0).toUpperCase()}
                </Avatar>
                <span>{value}</span>
              </div>
            );
          },
        },
      },
    ];

    next7Days.forEach((day) => {
      const formattedDate = day.format("DD MMM");
      columns.push({
        name: formattedDate,
        label: formattedDate,
        options: {
          customBodyRender: (value, tableMeta) => {
            if (!value) {
              // No shift assigned, show "Assign Shift" button
              return (
                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setUserId(shiftData[tableMeta.rowIndex].employeeId)
                      setDate(`${day.$y}-${day.$M + 1}-${day.$D.toString().length === 1 ? `0${day.$D}` : day.$D}`)
                      setIsOpen(true)
                    }}
                  >
                    Assign Shift
                  </Button>
                </div>
              );
            }
            return (
              <div
                style={{
                  backgroundColor: getColorForShift(value.time),
                  padding: "0.5em",
                  borderRadius: "8px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setUserId(shiftData[tableMeta.rowIndex].employeeId)
                  setDate(`${day.$y}-${day.$M + 1}-${day.$D.toString().length === 1 ? `0${day.$D}` : day.$D}`)
                  setIsOpen(true)
                }}
              >
                <div>{value.time}</div>
                <div style={{ fontSize: "0.85em", color: "#061103" }}>
                  {value.name}
                </div>
              </div>
            );
          },
        },
      });
    });

    return columns;
  };

  // Modal controls for shift CRUD
  const handleOpen = (shift = null) => {
    // setCurrentShift(shift);
    setOpen(true);
    // if (shift) {
    //     setNewShift(shift);
    // }
  };

  const handleClose = () => {
    setOpen(false);
    // setNewShift({ time: '', name: '' });
  };

  // MUI-Datatable options with Next/Previous buttons in the toolbar
  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: "none",
    pagination: false,
    customToolbar: () => (
      <div>
        {/* Previous Week Button */}
        <Button
          variant="outlined"
          size="small"
          onClick={handlePreviousWeek}
          style={{ marginRight: "1em" }}
        >
          Previous 7 Days
        </Button>

        {/* Next Week Button */}
        <Button
          variant="outlined"
          size="small"
          onClick={handleNextWeek}
          style={{ marginLeft: "1em" }}
        >
          Next 7 Days
        </Button>

        {/* Add Employee Button */}
        <Tooltip title="Assign Shift" arrow>
          <IconButton
            style={{
              backgroundColor: "#007bff",
              color: "white",
              borderRadius: "25px",
              marginLeft: "1em",
            }}
            onClick={() => handleOpen()}
          >
            <AddIcon style={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      </div>
    ),
  };

  // Function to return colors based on shift type
  const getColorForShift = (shift) => {
    switch (shift) {
      case "On Leave":
        return "#FFF5C1";
      case "Flexible":
        return "#c3e6cb";
      case "Week Off":
        return "#f5f5f5";
      default:
        return "#DCFFD5";
    }
  };

  // Navigation functions for previous and next 7 days
  const handleNextWeek = () => {
    const newStartDate = currentStartDate.add(7, "day");
    const newEndDate = newStartDate.add(6, "day"); // Next 7-day range
    setCurrentStartDate(newStartDate);
    setCurrentEndDate(newEndDate);
  };

  const handlePreviousWeek = () => {
    const newStartDate = currentStartDate.subtract(7, "day");
    const newEndDate = newStartDate.add(6, "day"); // Previous 7-day range
    setCurrentStartDate(newStartDate);
    setCurrentEndDate(newEndDate);
  };

  return (
    <div
      style={{
        height: "100%",
        // border: '1px solid red'
      }}
    >
      <MUIDataTable
        title={"Shift Board"}
        data={shiftData}
        columns={createColumns()}
        options={options}
      />

      <AssignShifts open={open} onClose={handleClose} />
      <ShiftAssignToUser
        date={date} open={isOpen}
        userid={userId}
        currentstartdate={currentStartDate}
        currentenddate={currentEndDate}
        fetchshifts={fetchShifts}
        onclose={() => setIsOpen(false)}
        setisopen={setIsOpen}
      />
    </div>
  );
};

export default UserShifts;
