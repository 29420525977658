import { notifyError } from "../notify/Notify";

const ServerUrl = `${process.env.REACT_APP_SERVER_URL}/api/`;
// const ServerUrl = `http://localhost:8000/api/`;
// const ServerUrl = `https://uminber-backend.o6e6dc.easypanel.host/api/`;
// let ServerUrl

async function postRequestWithFetch(url, body) {
  try {
    const res = await fetch(ServerUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    });
    const response = await res.json();
    if (response) {
      return response;
    } else {
      response && notifyError({ Message: response.err });
    }
  } catch (err) {
    console.log(err);
  }
}

async function postRequestWithFetchForFile(url, body) {
  try {
    const res = await fetch(ServerUrl + url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: body,
    });
    const response = await res.json();
    if (response) {
      return response;
    } else {
      response && notifyError({ Message: response.err });
    }
  } catch (err) {
    console.log(err);
  }
}

async function patchRequestWithFetchForFile(url, body) {
  try {
    const res = await fetch(ServerUrl + url, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: body,
    });
    const response = await res.json();
    if (response) {
      return response;
    } else {
      response && notifyError({ Message: response.err });
    }
  } catch (err) {
    console.log(err);
  }
}

async function putRequestWithFetch(url, body) {
  try {
    const res = await fetch(ServerUrl + url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    });
    const response = await res.json();
    if (response) {
      return response;
    } else {
      response && notifyError({ Message: response.err });
    }
  } catch (err) {
    console.log(err);
  }
}

async function patchRequestWithFetch(url, body) {
  try {
    const res = await fetch(ServerUrl + url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    });
    const response = await res.json();
    if (response) {
      return response;
    } else {
      response && notifyError({ Message: response.err });
    }
  } catch (err) {
    console.log(err);
  }
}

async function deleteRequestWithFetch(url, body) {
  try {
    const res = await fetch(ServerUrl + url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    });
    const response = await res.json();
    if (response) {
      return response;
    } else {
      response && notifyError({ Message: response.err });
    }
  } catch (err) {
    console.log(err);
  }
}

async function getRequestWithFetch(url, body) {
  try {
    const res = await fetch(ServerUrl + url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      // body: JSON.stringify(body),
    });
    const response = await res.json();
    if (response) {
      return response;
    } else {
      response && notifyError({ Message: response.err });
    }
  } catch (err) {
    notifyError({ Message: `${err}` });
  }
}

export {
  postRequestWithFetch,
  getRequestWithFetch,
  putRequestWithFetch,
  patchRequestWithFetch,
  deleteRequestWithFetch,
  postRequestWithFetchForFile,
  patchRequestWithFetchForFile,
};
