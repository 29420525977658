// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5em;
}

.logoContainer p {
    font-size: x-large;
    font-weight: 700;
    color: rgb(247, 241, 241);
}
`, "",{"version":3,"sources":["webpack://./src/component/navbar/SidebarMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":[".logoContainer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 1.5em;\n}\n\n.logoContainer p {\n    font-size: x-large;\n    font-weight: 700;\n    color: rgb(247, 241, 241);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
