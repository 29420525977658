import React, { useEffect } from 'react'
import './message.css';
import { Box, Button, Tooltip } from '@mui/material';
import { Typography } from '@mui/material';
import { Avatar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddMessage from './AddMessage';
import { getMessageList, getSentMessageList } from '../../component/service/APIs';
const role = localStorage.getItem('role')

const Message = () => {

  const [open, setOpen] = React.useState(false);
  const [messageList, setMessageList] = React.useState([]);

  const handleMessageList = () => {
    getMessageList().then((res) => {
      setMessageList(res)
    })
  }

  const handleSentMessageList = () => {
    getSentMessageList().then((res) => {
      setMessageList(res)
    })
  }

  const handleMessage = (role) => {
    if (role === 'super admin') {
      handleSentMessageList();
    } else {
      handleMessageList();
    }
  }
  useEffect(() => {
    handleMessage(role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'

    return `${day}-${month}-${year} (${hours}:${minutes} ${ampm})`;
  }

  return (
    <React.Fragment>

      {(role === 'super admin' || role === 'admin') && <div className="addButton" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title="Add new message" arrow>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: 'white',
              color: 'var(--textColor)',
              '&:hover': {
                backgroundColor: 'white', // Keeps the background color the same on hover
                // Add any other styles you want to remain unchanged on hover
              }
            }}
            onClick={() => setOpen(true)}
          >
            Compose
          </Button>
        </Tooltip>
      </div>}

      <div className='messageContainer'>
        {
          messageList ? messageList.map((row, index) => {
            return <div key={index} className='message'>
              <div className="messageHeader" >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      alt={row.from[0]} // Assuming this is the sender's initial
                      src="https://picsum.photos/200"
                      sx={{ boxShadow: 'var(--shadow)' }}
                    />
                    <Typography variant="body1" sx={{ marginLeft: 1, color: 'rgba(0,0,0,.8)' }}>
                      {row.from}
                    </Typography>
                  </Box>
                  <div>
                    <Typography variant="body2" sx={{ textAlign: 'right' }}>
                      {formatDate(row.date)}
                    </Typography>
                  </div>
                </Box>
              </div>
              <div className='messageContent'>
                Subject : {row.subject}<br />
                Descripton : {row.body}<br />
              </div>
            </div>
          }) : <div>no message found</div>
        }
        <AddMessage
          open={open}
          onHide={() => setOpen(false)}
          handleMessage={handleMessage}
        />
      </div >
    </React.Fragment >

  )
}

export default Message