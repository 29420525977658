import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  addHoliday,
  deleteHoliday,
  getHolidaysByMonth,
} from "../../component/service/apis/holiday/Holiday.api";
import { notifyError } from "../../component/notify/Notify";

// Setup the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

function Holiday() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEventPopupOpen, setIsEventPopupOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [holidayType, setHolidayType] = useState("");
  const [events, setEvents] = useState([
    {
      id: 1,
      title: "Coffee with John",
      start: new Date(2023, 10, 4),
      end: new Date(2023, 10, 4),
      allDay: true,
      type: "personal",
    },
    {
      id: 2,
      title: "Ski trip",
      start: new Date(2023, 11, 4),
      end: new Date(2023, 11, 4),
      allDay: true,
      type: "company",
    },
  ]);
  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));

  useEffect(() => {
    fetchHolidays(currentMonth);
  }, [currentMonth]);

  const fetchHolidays = async (month) => {
    const holidays = await getHolidaysByMonth(month);
    const formattedHolidays = holidays.map((holiday) => ({
      id: holiday.id,
      title: holiday.type, // Only display the holiday type on the calendar
      start: new Date(holiday.date),
      end: new Date(holiday.date),
      allDay: true,
      type: holiday.type,
      description: holiday.description, // Store description separately
    }));
    setEvents(formattedHolidays);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setDescription("");
    setDate("");
    setHolidayType("");
  };

  const handleAddHoliday = async () => {
    if (!description.trim() || !date || !holidayType) {
      notifyError({ Message: "All fields are required" });
      return;
    }

    const body = {
      date,
      type: holidayType,
      description,
    };

    const status = await addHoliday(body);

    if (status) {
      fetchHolidays(currentMonth); // Refetch holidays after adding
      handleClosePopup();
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsEventPopupOpen(true);
  };

  const handleCloseEventPopup = () => {
    setIsEventPopupOpen(false);
    setSelectedEvent(null);
  };

  const getHolidayColor = (event) => {
    switch (event.type) {
      case "holiday":
        return { bg: "#FFE6E6", text: "#8B0000" }; // Light red bg, dark red text for holidays
      case "week off":
        return { bg: "#E6E6FF", text: "#00008B" }; // Light blue bg, dark blue text for week offs
      default:
        return { bg: "#E6E6E6", text: "#4A4A4A" }; // Light grey bg, dark grey text for undefined types
    }
  };

  const eventStyleGetter = (event) => {
    const colors = getHolidayColor(event);
    return {
      style: {
        backgroundColor: colors.bg,
        color: colors.text,
        borderRadius: "0px",
        opacity: 1,
        border: "0px",
        display: "block",
        whiteSpace: "pre-wrap",
        fontSize: "1.1em",
        // fontWeight: "light",
      },
    };
  };

  const handleDeleteEvent = async () => {
    if (!selectedEvent || !selectedEvent.id) {
      notifyError({
        Message: "Unable to delete event. Invalid event selected.",
      });
      return;
    }

    const status = await deleteHoliday(selectedEvent.id);

    if (status) {
      fetchHolidays(currentMonth); // Refetch holidays after deleting
      handleCloseEventPopup();
    }
  };

  const PopupContent = (
    <Container
      className="popup"
      sx={{ display: "flex", flexDirection: "column", gap: "2em" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.5em",
        }}
      >
        <p>Add Holiday</p>
      </Box>
      <Box sx={{ width: "100%" }}>
        <FormControl sx={{ m: 1, display: "flex", gap: ".5em" }}>
          <TextField
            id="description"
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <TextField
            id="date"
            label="Date"
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <FormControl fullWidth required>
            <InputLabel id="holiday-type-label">Holiday Type</InputLabel>
            <Select
              labelId="holiday-type-label"
              id="holiday-type"
              value={holidayType}
              label="Holiday Type"
              onChange={(e) => setHolidayType(e.target.value)}
            >
              <MenuItem value="holiday">Holiday</MenuItem>
              <MenuItem value="week off">Week Off</MenuItem>
            </Select>
          </FormControl>
          <Button
            style={{ backgroundColor: "rgba(21, 64, 138,.8)" }}
            size="large"
            onClick={handleAddHoliday}
            variant="contained"
          >
            Add Holiday
          </Button>
          <Button
            style={{ opacity: ".8" }}
            color="error"
            size="large"
            variant="outlined"
            onClick={handleClosePopup}
          >
            Cancel
          </Button>
        </FormControl>
      </Box>
    </Container>
  );

  const EventPopupContent = (
    <Container
      className="popup"
      sx={{ display: "flex", flexDirection: "column", gap: "1em" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.5em",
        }}
      >
        <p>Event Details</p>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6">{selectedEvent?.type}</Typography>
        <Typography>Description: {selectedEvent?.description}</Typography>
        <Typography>Date: {selectedEvent?.start.toDateString()}</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          style={{ opacity: ".8" }}
          color="error"
          size="large"
          variant="contained"
          onClick={handleDeleteEvent}
          sx={{ flex: 1, mr: 1 }}
        >
          Delete
        </Button>
        <Button
          style={{ opacity: ".8" }}
          color="primary"
          size="large"
          variant="contained"
          onClick={handleCloseEventPopup}
          sx={{ flex: 1, ml: 1 }}
        >
          Close
        </Button>
      </Box>
    </Container>
  );

  const handleNavigate = (date) => {
    const newMonth = moment(date).format("MM");
    if (newMonth !== currentMonth) {
      setCurrentMonth(newMonth);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Holiday Calendar
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenPopup}
          >
            Add Holiday
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ backgroundColor: "#fff", height: "calc(100vh - 64px)" }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "100%" }}
          views={["month"]}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={handleEventClick}
          onNavigate={handleNavigate}
        />
      </Box>

      <Dialog
        open={isPopupOpen}
        onClose={handleClosePopup}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent>{PopupContent}</DialogContent>
      </Dialog>

      <Dialog
        open={isEventPopupOpen}
        onClose={handleCloseEventPopup}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <DialogContent>{EventPopupContent}</DialogContent>
      </Dialog>
    </Box>
  );
}

export default Holiday;
