import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { styled, Tooltip, IconButton, TextField, ButtonGroup, Button, Typography } from '@mui/material';
import './department.css'; // Ensure your CSS file is correctly linked
// import { getDepartment, updateDepartment, deleteDepartment } from '../../component/service/APIs';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddDepartment from './AddDepartment'; // Component for adding departments
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { deleteDepartment, getDepartment, updateDepartment } from '../../component/service/apis/department/department.api';

const Department = () => {
    const [departmentList, setDepartmentList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [indexDepartment, setIndexDepartment] = useState('');

    const body = (
        <div className='popup'>
            <Typography variant="h5" gutterBottom>
                Are you sure you want to delete this department?
            </Typography>
            <ButtonGroup sx={{ width: '100%' }} variant="outlined" aria-label="outlined button group">
                <Button sx={{ width: '100%', opacity: '.8' }} onClick={() => handleDelete()} color='success' variant='contained'>Yes</Button>
                <Button sx={{ width: '100%' }} onClick={() => setOpenPopup(false)} color='error'>Cancel</Button>
            </ButtonGroup>
        </div>
    );

    const handleDepartmentList = async () => {
        const res = await getDepartment();
        if (res) {
            setDepartmentList(res);
        }
    }

    const handleChangeDelete = (index) => {
        setIndexDepartment(index);
        setOpenPopup(true);
    }

    useEffect(() => {
        handleDepartmentList();
    }, []);

    const handleEdit = (index, department) => {
        setEditableRow(index);
        setEditValue(department);
    };

    const handleSave = (index) => {
        const departmentId = departmentList[index].id;
        const data = {
            deptId: departmentId,
            deptName: editValue
        };
        updateDepartment(data).then((res) => {
            res === true && handleDepartmentList();
        });
        setEditableRow(null);
    };

    const handleDelete = () => {
        setOpenPopup(false);
        const departmentId = departmentList[indexDepartment].id;
        const data = {
            deptId: departmentId,
        };
        deleteDepartment(data).then((res) => {
            res === true && handleDepartmentList();
        });
    };

    const handleCancel = () => {
        setEditableRow(null);
    };

    const columns = [
        {
            name: "S.No",
            label: "S.No",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => <div>{dataIndex + 1}</div>,
            },
        },
        {
            name: "department_name",
            label: "Department",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return <TextField
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            size="small"
                        />;
                    }
                    return <div>{departmentList[dataIndex].department_name}</div>;
                },
            },
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    if (editableRow === dataIndex) {
                        return (
                            <div>
                                <IconButton color='success' onClick={() => handleSave(dataIndex)}>
                                    <DoneIcon fontSize='small' />
                                </IconButton>
                                <IconButton color='error' onClick={handleCancel}>
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            </div>
                        );
                    }
                    return (
                        <div>
                            <IconButton onClick={() => handleEdit(dataIndex, departmentList[dataIndex].department_name)}>
                                <EditIcon fontSize='small' />
                            </IconButton>
                            <IconButton color='error' onClick={() => handleChangeDelete(dataIndex)}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </div>
                    );
                },
            },
        },
    ];

    const CustomToolbar = () => (
        <Tooltip title='Add Department'>
            <IconButton onClick={() => setOpen(true)}>
                <AddIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        rowsPerPage: 15,
        rowsPerPageOptions: [15, 30, 50],
        elevation: 0,
        fixedHeader: true,
        responsive: 'standard',
        selectableRows: 'none',
        customToolbar: () => <CustomToolbar />,
    };

    return (
        <React.Fragment>
            <StyledMUIDataTable
                title={"Department List"}
                data={departmentList}
                columns={columns}
                options={options}
            />
            <AddDepartment
                open={open}
                onHide={() => setOpen(false)}
                setOpen={setOpen}
                handleDepartmentList={handleDepartmentList}
            />
            <ResponsiveDialog
                open={openPopup}
                onHide={() => setOpenPopup(false)}
                Content={body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    );
}

export default Department;

const StyledMUIDataTable = styled(MUIDataTable)({
    '&.MuiPaper-root': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    },
    '&.MuiTableCell-head': {
        backgroundColor: 'var(--glass) !important',
        backdropFilter: 'var(--blurr) !important',
    }
});
