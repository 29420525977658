// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    padding: var(--pd);
    gap: 1em;
}

@media (max-width: 768px) {
    .container {
        flex-wrap: wrap;
    }
}

.dashboard{
    display: flex;
    flex-direction: column;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/views/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    padding: var(--pd);\n    gap: 1em;\n}\n\n@media (max-width: 768px) {\n    .container {\n        flex-wrap: wrap;\n    }\n}\n\n.dashboard{\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
