import { notifyError, notifySuccess } from "../../../notify/Notify";
import { deleteRequestWithFetch, getRequestWithFetch, patchRequestWithFetch, postRequestWithFetch } from "../../FetchingMethod";

const getRole = async () => {
    const res = await getRequestWithFetch(`role/list`)
    if (res?.status === true) {
        return await res?.data;
    } else {
        notifyError({ Message: res?.message || "Server Error" });
    }
}


const addRole = async (body) => {
    const res = await postRequestWithFetch(`role/add`, body)
    if (res?.status === true) {
        notifySuccess({ Message: res.message })
        return await res?.status;
    } else {
        notifyError({ Message: res?.message || "Server Error" });
    }
}

const updateRole = async ({ roleId, roleName }) => {
    const res = await patchRequestWithFetch(`role/update/${roleId}`, { roleName })
    if (res?.status === true) {
        notifySuccess({ Message: res.message })
        return await res?.status;
    } else {
        notifyError({ Message: res?.message });
    }
}

const deleteRole = async ({ roleId }) => {
    const res = await deleteRequestWithFetch(`role/delete/${roleId}`)
    if (res?.status === true) {
        notifySuccess({ Message: res.message })
        return await res?.status;
    } else {
        notifyError({ Message: res?.message });
    }
}

export {
    getRole,
    addRole,
    updateRole,
    deleteRole
}