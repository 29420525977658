import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={props.open}
        onClose={props.onHide}
        aria-labelledby="responsive-dialog-title"
        {...props}
      >
        <DialogContent>
          {props.Content}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
