import React, { useState } from 'react'
import { Box, Button, Container, FormControl, TextField } from '@mui/material';
import ResponsiveDialog from '../../component/dialogBox/ResponsiveDialog';
import { addDepartment } from '../../component/service/apis/department/department.api';

const AddRole = (props) => {

    const [dept, setDepartment] = useState('');

    const handleAdd = () => {
        const data = {
            name: dept
        }
        addDepartment(data).then((res) => {
            res === true && props.handleDepartmentList()
        })
        setDepartment('');
        props.setOpen(false)
    }

    const Body = <React.Fragment>
        <Container className='popup' sx={{ display: 'flex', flexDirection: 'column', gap: '2em' }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5em',
            }}>
                <p>
                    Add Department
                </p>
            </Box>
            <Box sx={{ width: '100%' }}>
                <FormControl sx={{ m: 1, display: 'flex', gap: '.5em' }}>
                    <TextField id="role" label="Department Name" variant="outlined" value={dept} onChange={(e) => setDepartment(e.target.value)} />
                    <Button style={{ backgroundColor: 'rgba(21, 64, 138,.8)' }} size='large' onClick={() => handleAdd()} variant="contained">Add Department</Button>
                    <Button style={{ opacity: '.8' }} color='error' size='large' variant="outlined" onClick={props.onHide}>Cancel</Button>
                </FormControl>
            </Box>
        </Container>
    </React.Fragment>

    return (
        <React.Fragment>
            <ResponsiveDialog
                open={props.open}
                onHide={props.onHide}
                Content={Body}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            />
        </React.Fragment>
    )
}

export default AddRole