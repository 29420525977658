import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/Inbox";
import GroupIcon from "@mui/icons-material/Group";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupsIcon from "@mui/icons-material/Groups";
import BallotIcon from "@mui/icons-material/Ballot";
import DashboardIcon from "@mui/icons-material/Dashboard";
import "./SidebarMenu.css";
import { useNavigate, useLocation } from "react-router-dom";

const listItem = [
  { text: "Dashboard", icon: <DashboardIcon />, url: "/" },
  { text: "Attendance Log", icon: <CoPresentIcon />, url: "/attendance" },
  { text: "Leaves", icon: <ExitToAppIcon />, url: "/leaves" },
];

const userRole = localStorage.getItem("role");

if (
  userRole === "manager" ||
  userRole === "admin" ||
  userRole === "super admin"
) {
  listItem.push({ text: "Users", icon: <GroupsIcon />, url: "/users" });
}
if (userRole === "admin" || userRole === "super admin") {
  listItem.push({
    text: "Managers",
    icon: <ManageAccountsIcon />,
    url: "/managers",
  });
}
if (userRole === "super admin") {
  listItem.push({
    text: "Admins",
    icon: <WorkspacePremiumIcon />,
    url: "/admins",
  });
  listItem.push({ text: "Role", icon: <SwitchAccountIcon />, url: "/roles" });
  listItem.push({ text: "Position", icon: <GroupIcon />, url: "/position" });
  listItem.push({
    text: "Department",
    icon: <AccountTreeIcon />,
    url: "/department",
  });
  listItem.push({
    text: "Leave Type",
    icon: <BallotIcon />,
    url: "/leavesType",
  });
  listItem.push({
    text: "Documents",
    icon: <DocumentScannerIcon />,
    url: "/documents",
  });
  listItem.push({
    text: "Shifts",
    icon: <AssignmentTurnedInIcon />,
    url: "/shifts",
  });
  listItem.push({
    text: "Shift Board",
    icon: <AssignmentTurnedInIcon />,
    url: "/assign-shift",
  });
  // listItem.push({
  //   text: "Holidays",
  //   icon: <AssignmentTurnedInIcon />,
  //   url: "/holidays",
  // });
}

const SidebarMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleListItemClick = (event) => {
    navigate(event);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360 }}>
      <Box className="logoContainer" sx={{ width: "100%", maxWidth: 360 }}>
        {/* <p>Attendance.</p> */}
        <img src="./logo.png" alt="LOGO" style={{ height: "4em" }} />
      </Box>

      <nav aria-label="main mailbox folders">
        <List
          sx={{
            display: "grid",
            gap: ".7em",
            maxHeight: "calc(90vh - 100px)", // Set the maximum height here
            overflowY: "auto", // Enable vertical scrolling
            "::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for Chrome, Safari and Opera
            },
            "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
            scrollbarWidth: "none", // Hide scrollbar for Firefox
          }}
        >
          {listItem.map((item) => (
            <ListItem disablePadding key={item.text}>
              <ListItemButton
                selected={location.pathname === item.url}
                onClick={() => handleListItemClick(item.url)}
                sx={{
                  color: "#ffffff",
                  "&:hover": {
                    borderRight: "7px solid rgba(1, 120, 219)",
                    color: "#0178DB",
                    backgroundColor: "rgba(179, 220, 255,.9)",
                  },
                  ...(location.pathname === item.url && {
                    borderRight: "8px solid rgba(1, 120, 219)",
                    color: "#0178DB",
                    "&.Mui-selected": {
                      backgroundColor: "rgba(179, 220, 255,.9)",
                    },
                  }),
                  // paddingLeft:'3em',
                  marginLeft: "1.1em",
                  borderRadius: ".5em 0 0 .5em",
                  padding: ".3em 0 .3em 1.7em",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color: "#ffffff",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </nav>
    </Box>
  );
};

export default SidebarMenu;
