import { notifyError, notifySuccess } from "../../../notify/Notify";
import { deleteRequestWithFetch, getRequestWithFetch, patchRequestWithFetch, postRequestWithFetch } from "../../FetchingMethod";

const getPosition = async () => {
    const res = await getRequestWithFetch(`position/list`)
    if (res && res.status === true) {
        return await res.data;
    } else {
        res && notifyError({ Message: res.error });
    }
}

const addPosition = async (body) => {
    const res = await postRequestWithFetch(`position/add`, body)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const updatePosition = async ({ positionId, positionName }) => {
    const res = await patchRequestWithFetch(`position/update/${positionId}`, { positionName })
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

const deletePosition = async ({ positionId }) => {
    const res = await deleteRequestWithFetch(`position/delete/${positionId}`)
    if (res && res.status === true) {
        notifySuccess({ Message: res.message })
        return await res.status;
    } else {
        notifyError({ Message: res.error });
    }
}

export {
    getPosition,
    addPosition,
    updatePosition,
    deletePosition
}